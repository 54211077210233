// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlAngularApp: 'https://test.storemanager.tsunamiplatform.it',
  silentRefreshRedirectUri: 'https://test.storemanager.tsunamiplatform.it/silent_renew.html',

  codiceEntitaGefass : 'GFS00',

  webApiUrl: 'https://test.storemanager.tsunamiplatform.it/be/', // 63696
  webApiUploadFile: 'api/files/uploadFile',

  baseUrlAssets: 'assets/',

  //KEYCLOAK
  keycloakAuthorityUrl: 'https://accountstest.aon.it/realms/tsunamiex1',
  keycloakRealm: 'tsunamiex1',
  keycloakClientId: 'tsunamiapp2',
};
