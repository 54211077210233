import { PaginationResult } from './../models/paginationResult';
import { FileViewModel } from './../models/FileViewModel';

export class Prodotto {
  id: number;
  nome: string;
  idBanca: number;
  idCompagnia: number;
  banca: string;
  compagnia: string;
  codiceTsunami: string;
  codiceTariffaBanca: string;
  codiceTariffaCompagnia: string;
  descrizione: string;
  descrizioneCommerciale: string;
  tipologiaCopertura: string;
  targetRiferimento: string;
  livelloCopertura: number;
  provvigioni: number;
  campagnaVendita: string;
  version: string;
  source: string;
  abilitato: boolean;
  emissioneAbilitata: boolean;
  sbloccoOnere: boolean;
  comparatoreErbavoglio: boolean;
  isBundle: boolean;
  hasPostVendita: boolean;
  idProdottoBundle: number;
  eliminato: boolean;
  files: FileViewModel[]
}

export class ProdottiPagination implements PaginationResult {
  totaleRecords: number;
  records: Prodotto[];
}
