import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PaginaEditComponent } from './pagina-edit/pagina-edit.component';
import { PaginaListComponent } from './pagina-list/pagina-list.component';

import { SharedAppModule } from '../shared-app/shared-app.module';
import { PaginaService } from './pagina.service';

const paginaRoutes = [
  { path: '', component: PaginaListComponent },
  { path: 'pagina/:id', component: PaginaEditComponent }
]

@NgModule({
  imports: [
    SharedAppModule,
    RouterModule.forChild(paginaRoutes)
  ],
  declarations: [PaginaEditComponent, PaginaListComponent],
  providers: [PaginaService]
})
export class PaginaModule { }
