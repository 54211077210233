import { PaginationResult } from '../models/paginationResult';

export class Operatore {
  id: string
  ruolo: string
  nome: string
  cognome: string
  matricola: string
  codiceRui: string
  abilitatoIsvap: boolean
  abilitatoMifid: boolean
  dataAbilitazione: Date
  email: string
  username: string
  idEntita: number
  entita: string
  tipoEntita: string
  eliminato: boolean
}

export class OperatoriPagination implements PaginationResult {
  totaleRecords: number;
  records: Operatore[];
}
