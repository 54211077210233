import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { Prodotto } from '../prodotto';
import { FileViewModel } from './../../models/FileViewModel';
import { CommonService } from './../../services/common.service';
import { FileService } from './../../services/file.service';
import { SelectItem, Message } from 'primeng/api';

@Component({
  selector: 'app-modal-prodotto-edit',
  templateUrl: './modal-prodotto-edit.html',
  styleUrls: ['./modal-prodotto-edit.css']
})
export class ModalProdottoEditComponent implements OnInit {

  @Input() displayModal: boolean;
  @Input() prodotto: Prodotto;
  @Input() file: FileViewModel;
  @Input() siglaTariffa: string;
  
  @Output() adeguato: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() esitoSalvato: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalInfoMessage: string;
  displayInfo = false;

  sources: SelectItem[];
  versions: SelectItem[];
  versioniPiattaforme: Observable<SelectItem[]>;
  tipiFile: SelectItem[];
  prodottiBundle:  Observable<SelectItem[]>;
  templatesFile: SelectItem[];
  toSaveOptions: SelectItem[];

  msgs: Message[] = [];
  
  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  constructor(private fileService: FileService,
              private commonService: CommonService) {  }

  /**
   *
   */
  ngOnInit() {
    this.sources = this.commonService.getSources();
    this.tipiFile = this.commonService.getTipiFile();
    this.templatesFile = this.commonService.getTemplatesFile();
    this.toSaveOptions = this.commonService.getSaveFileOptions();
  }

  
  chiudiDialogModal() {
    this.showLoading = false;
    this.showErrorDialog = true;
    this.displayModal = false; 
  }
  
  public aggiornaFile(titoloFile, tipoFile, template, daSalvare, file: FileViewModel) {
    this.showLoading = true;
    file.titoloFile = titoloFile.value;
    file.idTipoFile = tipoFile.value;
    file.template = template.value === 'null' ? null : template.value;
    file.daSalvare = template.value === 'null' ? null : JSON.parse(daSalvare.value);

    this.fileService.updateFile(file).subscribe(
      () => this.onUpdatedFile(),
      (error: any) => this.httpErrorMessage(error, 'Errore nell\' aggiornamento del file'));
  }

  /**
   *
   */
   onUpdatedFile(): void {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.displayModal = false; 
    this.msgs.push({severity: 'success', summary: 'Modifica registrata', detail: ''});
  }

  /**
   *
   */
  private httpErrorMessage(error: any, message: string) {
    this.showLoading = false;
    this.showErrorDialog = true;
    this.errorMessage = error._body;
    this.displayModal = false; 
    this.msgs.push({severity: 'error', summary: message, detail: ''});
  }
  
}
