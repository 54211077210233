import { Component, OnInit } from '@angular/core';


import { PaginaService } from '../pagina.service';
import { PaginaInputModel } from 'app/pagina/pagina';
import { PagineInputModel } from 'app/pagina/models/pagine-input.model';

@Component({
  selector: 'app-pagina-list',
  templateUrl: './pagina-list.component.html',
  styleUrls: ['./pagina-list.component.css']
})
export class PaginaListComponent implements OnInit {

  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  totaleVetrineHome: number;
  pagine: PaginaInputModel[];

  message: any;

  /**
   *
   * @param paginaService
   */
  constructor(public paginaService: PaginaService) { }

  /**
   *
   */
  ngOnInit() {
  }

  /**
   *
   * @param event
   */
  loadPagine(event) {
    this.showLoading = true;

    const filter: PagineInputModel = {
      page: (event.first / 15) + 1,
      filter: event.globalFilter
    }

    this.paginaService.getPagine(filter)
        .subscribe(
          response => this.onPagineRetrievedSuccess(response),
          error => this.onPagineRetrievedError(error)
        );
  }

  /**
   *
   * @param response
   */
  onPagineRetrievedSuccess(response) {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.totaleVetrineHome = response.totaleRecords
    this.pagine = response.records;
  }

  /**
   *
   * @param error
   */
  onPagineRetrievedError(error) {
    this.showErrorDialog = true;
    this.errorMessage = error._body;
  }
}
