import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-dropzone-files',
  templateUrl: './dropzone-files.component.html',
  styleUrls: ['./dropzone-files.component.css']
})
export class DropzoneFilesComponent implements OnInit {
  
  @Input() idEntita?: number;
  @Input() idProdotto?: number;
  @Input() idPagina?: number;
  
  @Output() fileUploadedSuccess: EventEmitter<string> = new EventEmitter<string>();
  @Output() fileUploadedError: EventEmitter<string> = new EventEmitter<string>();
  
  @ViewChild('fileDropRef', { static: true }) fileDropEl: ElementRef;

  public uploader: FileUploader;

  files: any[] = [];
  tipiFile: SelectItem[];
  templatesFile: SelectItem[];
  toSaveOptions: SelectItem[];

  public inputValues: string[] = [];
  public tipiFileValues: string[] = [];
  public templateFileValues: string[] = [];
  public templateFileDaSalvareValues: string[] = [];

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.uploader = this.createFileUploader();
    this.tipiFile = this.commonService.getTipiFile()
    this.templatesFile = this.commonService.getTemplatesFile();
    this.toSaveOptions = this.commonService.getSaveFileOptions();
  }


  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    // for (const item of files) {
    //   item.progress = 0;
    //   this.files.push(item);
    // }
    // this.fileDropEl.nativeElement.value = '';
    // this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   *
   */
  private createFileUploader(): FileUploader {
    let uploader = new FileUploader({ url: environment.webApiUrl + environment.webApiUploadFile, authTokenHeader: localStorage.getItem('access_token') });

    // Uploader Events Settings -------------------------------------------------
    uploader.onBeforeUploadItem = (item) => item.withCredentials = false;
    uploader.onBuildItemForm = (fileItem, form) => this.onBuildItemForm(fileItem, form);
    uploader.onSuccessItem = (file) => this.onFileUploadedSuccess(file);
    uploader.onErrorItem = () => this.onFileUploadedError();
    uploader.onCompleteAll = () => { 
      uploader.clearQueue(), 
      this.inputValues = [], 
      this.tipiFileValues = [], 
      this.templateFileValues = [], 
      this.templateFileDaSalvareValues = []
    }
    // --------------------------------------------------------------------------

    return uploader;
  }


  /**
   *
   * @param fileItem
   * @param form
   */
  private onBuildItemForm(fileItem, form) {
    form.append('idEntita', this.idEntita);

    if (!_.isNil(this.idProdotto))
      form.append('idProdotto', this.idProdotto);

    if (!_.isNil(this.idPagina))
      form.append('idPagina', this.idPagina);

    if (this.inputValues.length > 0)
      form.append('titoloFile', this.inputValues[0])

    if (this.tipiFileValues.length > 0)
      form.append('tipoFile', this.tipiFileValues[0])

    if (this.templateFileValues.length > 0)
      form.append('templateFile', this.templateFileValues[0])

    if (this.templateFileDaSalvareValues.length > 0)
      form.append('daSalvare', this.templateFileDaSalvareValues[0])

    return { fileItem, form }
  }

  /**
   *
   */
  private onFileUploadedSuccess(fileItem: FileItem) {
    this.uploader.removeFromQueue(fileItem);

    if (this.inputValues.length > 1)
      this.inputValues.splice(0, 1);

    if (this.tipiFileValues.length > 1)
      this.tipiFileValues.splice(0, 1);

    if (this.templateFileValues.length > 1)
      this.templateFileValues.splice(0, 1);

    if (this.templateFileDaSalvareValues.length > 1)
      this.templateFileDaSalvareValues.splice(0, 1);

    if (this.uploader.queue.length === 0)
      this.uploader.progress = 0;

    this.fileUploadedSuccess.emit();
  }

  /**
   *
   */
  private onFileUploadedError() {
    this.fileUploadedError.emit();
  }
}
