<ngx-loading [show]="showLoading"></ngx-loading>
<p-table 
  [value]="prodotti"
  [rows]="maxRows"
  [paginator]="true"
  [responsive]="true"
  [lazy]="true"
  [autoLayout]="true"
  [totalRecords]="totaleProdotti" styleClass="ui-table-customers" [rowHover]="true"
  [showCurrentPageReport]="true" [rowsPerPageOptions]="[15,25,50]"
  [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [filterDelay]="500"
  (onLazyLoad)="loadProdotti($event)"
  #dt>
  
  <p-header><h5>Lista prodotto</h5></p-header>
  <ng-template pTemplate="header">
    <tr>
      <th>Prodotto</th>
      <th>Banca</th>
      <th>Compagnia</th>
      <th>Codice Tsunami</th>
      <th>Tariffa Banca</th>
      <th>Tariffa Compagnia</th>
      <th>Abilitato</th>
      <th>Emettibile</th>
      <th>PostVendita</th>
      <th>###</th>
    </tr>
    <tr>
      <th>
        <input pInputText type="text" [(ngModel)]="filterNome" (input)="dt.filter($event.target.value, 'nome', 'contains')" placeholder="Search">
      </th>
      <th>
        <p-dropdown [options]="banche | async" [(ngModel)]="filterBanca" filter="filter" (onChange)="dt.filter($event.value, 'banca', 'contains')" styleClass="ui-column-filter" placeholder="Seleziona una banca" [showClear]="true"></p-dropdown>
      </th>
      <th>
        <p-dropdown [options]="compagnie | async" [(ngModel)]="filterCompagnia" filter="filter" (onChange)="dt.filter($event.value, 'compagnia', 'contains')" styleClass="ui-column-filter" placeholder="Seleziona una compagnia" [showClear]="true"></p-dropdown>
      </th>
      <th></th>
      <th style="text-align:center"></th>
      <th></th>
      <th style="text-align:center"></th>
      <th style="text-align:center"></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record>
    <tr>
      <td><strong [ngClass]="record.eliminato ? 'text-danger' : 'text-success'">{{record.nome}}</strong></td>
      <td>{{record.banca}}</td>
      <td>{{record.compagnia}}</td>
      <td>{{record.codiceTsunami}}</td>
      <td>{{record.codiceTariffaBanca}}</td>
      <td>{{record.codiceTariffaCompagnia}}</td>
      <td>{{record.abilitato}}</td>
      <td>{{record.emissioneAbilitata}}</td>
      <td>{{record.hasPostVendita}}</td>
      <td>

        <a class="btn btn-primary" [routerLink]="['/prodotti/prodotto', record.id]"
          [queryParams]="{ nome: dt.filters?.nome?.value, banca: filterBanca, compagnia: filterCompagnia }" queryParamsHandling="merge">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>
        <a class="btn btn-danger" (click)="eliminaProdotto(record.id)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </td>
    </tr>
  </ng-template>

</p-table>
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" global="false" appendTo="body"></p-confirmDialog>
<p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog>
