import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  toggleSidebarActive: boolean = false;
  year: number = new Date().getFullYear();

  /**
   *
   */
  constructor(public authService: AuthService, public location: Location, public titleService: Title) {
    this.titleService.setTitle(":: Vetrina Manager::");
    if (!environment.production) {
      this.titleService.setTitle(":: Vetrina Manager Test::");
    }
  }
  
  ngOnInit() {}
  
  toggleSidebar() {
    this.toggleSidebarActive = !this.toggleSidebarActive;
  }  

  public login(): void {
    this.authService.login();
  }

  public logout(): void {
    debugger
    this.authService.logout();
  }
}
