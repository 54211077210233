<ngx-loading [show]="showLoading"></ngx-loading>
<button [routerLink]="['/pagine/pagine']" queryParamsHandling="preserve" style="margin-bottom: 5px; color:rgb(76, 76, 247); border-color: rgb(18, 214, 67);"><i class="fa fa-reply fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>Torna alla lista pagine</button>

<div class="card bg-light">
  <div class="card-header">
    {{pageTitle}}
  </div>

  <form [formGroup]="paginaForm" (ngSubmit)="savePagina()" novalidate>
    <div class="card-body">
      <fieldset>
        <div class="form-group" [ngClass]="{'has-error': displayMessage.titoloPagina }">
            <label class="col-md-4 control-label" for="titoloPaginaId"><span class="badge badge-secondary">Titolo Pagina</span></label>
            <div class="col-md-8">
                <input class="form-control" id="titoloPaginaId" type="text" placeholder="Titolo Pagina(obbligatorio)" formControlName="titoloPagina" />
                <span class="help-block" *ngIf="displayMessage.titoloPagina">{{displayMessage.titoloPagina}}</span>
            </div>
        </div>

        <div class="form-group" [ngClass]="{'has-error': displayMessage.permalink }">
            <label class="col-md-4 control-label" for="permalinkId"><span class="badge badge-secondary">Permalink</span></label>
            <div class="col-md-8">
                <input class="form-control" id="permalinkId" type="text" placeholder="Permalink" formControlName="permalink" />
                <span class="help-block" *ngIf="displayMessage.permalink">{{displayMessage.permalink}}</span>
            </div>
        </div>

        <div class="form-group" [ngClass]="{'has-error': displayMessage.entita }">
          <label class="col-md-4 control-label" attr.for="entotaId"><span class="badge badge-secondary">Entita</span></label>
          <div class="col-md-8">
              <p-dropdown [options]="entitas | async" [style]="{'width':'100%'}"  placeholder="Seleziona Entita (obbligatorio)" formControlName="idEntita" [filter]="true"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.entita">{{displayMessage.entitaBanca}}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="col-md-2 control-label" attr.for="contenutoId"><span class="badge badge-secondary">Contenuto</span></label>
          <div class="col-md-12">
              <p-editor id="contenutoId" formControlName="contenuto"></p-editor>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-md-12" attr.for="tagsId"><span class="badge badge-secondary">Tags</span></label>
          <div class="col-md-12">
            <p-chips id="tagsId" formControlName="tags" [allowDuplicate]="false" [max]="10"></p-chips>
          </div>
        </div>

        <div class="form-group" class="mb-5">
          <label class="control-label col-md-12" attr.for="isOnlyPdfId"><span class="badge badge-secondary">Solo PDF</span></label>
          <div class="col-md-12" style="margin-left: 0%;">
              <p-toggleButton id="isOnlyPdfId" formControlName="isOnlyPdf" onLabel="Si" offLabel="No" onIcon="fa-check-square-o" offIcon="fa-square"></p-toggleButton>
          </div>
        </div>
      </fieldset>
    </div>
      <div class="card-footer bg-secondary panel-fixed-bottom">
        <button type="submit" class="btn btn-success"[disabled]="!paginaForm.valid || paginaExists" >Salva</button>
      </div>
  </form>

  <p-dataView [value]="files" emptyMessage="nessun file presente" *ngIf="showFilesUpload">
    <ng-template let-file pTemplate="listItem">
      <div class="card border-info mb-3">
        <div class="card-header text-info"><strong>{{file.nomeFile}}</strong></div>
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <input class="form-control" #titoloFile type="text" [value]="file.titoloFile" placeholder="Titolo File" />
            </div>
            <div class="col-4">
              <p-chips #virtualPath [(ngModel)]="file.virtualPath" placeholder="Path virtuale"></p-chips>
            </div>
            <div class="col-4">
              <select class="form-control" #tipoFile>
                <option *ngFor="let tipoFile of tipiFile" [value]="tipoFile.value" [selected]="tipoFile.value === file.idTipoFile">
                  {{tipoFile.label}}
                </option>
              </select>
            </div>
          </div>
        </div>
          <div class="card-footer">
            <button type="button" class="btn btn-primary" (click)="aggiornaFile(titoloFile, tipoFile, virtualPath, file)">Aggiorna</button>
            <button type="button" class="btn btn-danger" (click)="eliminaFile(file)">Elimina File</button>
            <button type="button" class="btn btn-primary" (click)="downloadFile(file.id, file.nomeFile)">Download</button>
          </div>
      </div>
    </ng-template>
  </p-dataView>

  <!--<app-upload-files [idPagina]="idPagina" [idEntita]="pagina?.idEntita" *ngIf="showFilesUpload"
    (fileUploadedSuccess)="onFileUploadedSuccess()" (fileUploadedError)="onFileUploadedError()">
  </app-upload-files>-->

  <app-dropzone-files 
    *ngIf="showFilesUpload"
    [idPagina]="idPagina"
    [idEntita]="pagina?.idEntita"
    (fileUploadedSuccess)="onFileUploadedSuccess()"
    (fileUploadedError)="onFileUploadedError()">
  </app-dropzone-files>

</div>

<br />
<!-- <div class="card-footer bg-secondary" style="text-align: center;">
  <button type="submit" class="btn btn-success"[disabled]="!paginaForm.valid || paginaExists" >Salva</button>
</div> -->

<p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog>
