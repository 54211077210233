import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OperatoreListComponent } from './operatore-list/operatore-list.component';
import { OperatoreEditComponent } from './operatore-edit/operatore-edit.component';

import { SharedAppModule } from '../shared-app/shared-app.module';

import { OperatoreService } from './operatore.service';
import { OperatoreConstants } from './operatore.constants';

const userRoutes = [
  { path: '', component: OperatoreListComponent },
  { path: 'operatore/:id', component: OperatoreEditComponent }
]

@NgModule({
  imports: [
    SharedAppModule,
    RouterModule.forChild(userRoutes)
  ],
  declarations: [
    OperatoreListComponent,
    OperatoreEditComponent
  ],
  providers: [
    OperatoreService,
    OperatoreConstants
  ]
})
export class OperatoreModule { }
