<div class="container mt-3 mb-5" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef  ng2FileSelect [uploader]="uploader" id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
    <img src="assets/dnd.png" alt="">
    <h3>Drag & drop file qui!</h3>
    <h3>oppure</h3>
    <label for="fileDropRef">Naviga la cartella</label>
</div>

<div class="files-list">
    <div class="single-file" style="justify-content: center;" *ngIf="uploader.queue.length">
        <button type="button" class="btn btn-success btn-s mr-3" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
            <span class="glyphicon glyphicon-upload"></span> Upload tutti
        </button>
        <button type="button" class="btn btn-warning btn-s mr-3" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
            <span class="glyphicon glyphicon-ban-circle"></span> Cancella tutti
        </button>
        <button type="button" class="btn btn-danger btn-s mr-3" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
            <span class="glyphicon glyphicon-trash"></span> Rimuovi tutti
        </button>
    </div>
    <div class="single-file" *ngFor="let file of uploader.queue; let i = index">
        <img src="assets/document.png" width="45px" alt="file">
        <div class="info">
            <h4 class="name">
                {{ file?.file?.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.file?.size) }}
            </p>
            <div class="form-row">
                <div class="form-group col-mb-3">
                    <input class="form-control" type="text" name="inputTitoloFile{{i}}" [(ngModel)]="inputValues[i]" placeholder="Titolo File">
                </div>
                <div class="form-group col-mb-3">
                    <select class="form-control" name="selectTipoFile{{i}}" [(ngModel)]="tipiFileValues[i]">
                        <option *ngFor="let tipoFile of tipiFile" [ngValue]="tipoFile.value">
                            {{tipoFile.label}}
                        </option>
                    </select>
                </div>
            <!-- </div>
            <div class="form-row"> -->
                <div class="form-group col-mb-3">
                    <select class="form-control" name="selectTemplateFile{{i}}"  [(ngModel)]="templateFileValues[i]">
                        <option *ngFor="let templateFile of templatesFile" [value]="templateFile.value">
                            {{templateFile.label}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-mb-3">
                    <select class="form-control" name="selectTemplateFileDaSalvare{{i}}"  [(ngModel)]="templateFileDaSalvareValues[i]">
                        <option *ngFor="let daSalvare of toSaveOptions" [value]="daSalvare.value">
                            {{daSalvare.label}}
                        </option>
                    </select>
                </div>
            </div>
            <app-progress *ngIf="file.isUploading" [progress]="uploader.progress"></app-progress>
        </div>
        <img src="assets/delete.png" class="delete" width="20px" alt="file" (click)="file.remove()">
    </div>
</div>