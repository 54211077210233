import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';

import { CommonService } from './../../services/common.service';
import { ProdottoService } from './../../prodotto/prodotto.service';
import { Prodotto } from './../../prodotto/prodotto';
import { DialogImplementation } from 'app/interfaces/DialogImplementation';

import { ActivatedRoute } from '@angular/router';
import { ProdottiInputModel } from '../models/prodotti-input.model';
import { finalize } from 'rxjs/operators';
import { LazyLoadEvent, ConfirmationService, SelectItem, Message } from 'primeng/api';
import { Table } from 'primeng';

@Component({
  selector: 'app-prodotto-list',
  templateUrl: './prodotto-list.component.html',
  styleUrls: ['./prodotto-list.component.css']
})
export class ProdottoListComponent implements OnInit, DialogImplementation, AfterViewInit {

  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  filters: ProdottiInputModel;
  filterBanca: any;
  filterCompagnia: any;
  filterNome: string;
  currentPage: number;

  maxRows: number = 15

  totaleProdotti: number;
  prodotti: Prodotto[];

  compagnie: Observable<SelectItem[]>;
  banche: Observable<SelectItem[]>;
  
  @ViewChild('dt') dt: Table;

  constructor(private prodottoService: ProdottoService,
              private commonService: CommonService,
              public route: ActivatedRoute,
              private confirmationService: ConfirmationService) {}

  /**
   *
   */
  ngOnInit() {
    this.banche = this.commonService.getBanche();
    this.compagnie = this.commonService.getCompagnie();
    
    this.filterNome = this.route.snapshot.queryParams['nome'] || null;
    this.filterBanca = +this.route.snapshot.queryParams['banca'] || null
    this.filterCompagnia = +this.route.snapshot.queryParams['compagnia'] || null;
  }

  ngAfterViewInit() {
    if (this.dt != null && !this.dt.filters.nome) {
      const queryParamNomeProdotto = this.route.snapshot.queryParams['nome'] === 'null'
        ? null
        : this.route.snapshot.queryParams['nome']
      this.dt.filters.nome = { value: '', matchMode: undefined };
      this.dt.filters.nome.value = queryParamNomeProdotto || '';
    }
  }

  /**
   *
   * @param event
   */
  loadProdotti(event: LazyLoadEvent) {
      this.showLoading = true;
      const rows = event.rows || this.maxRows;

      const nomeProdotto = (event.filters.nome && event.filters.nome.value) 
        ? event.filters.nome.value
        : this.filterNome;

      const idBanca = (event.filters.banca && event.filters.banca.value && event.filters.banca.value !== 0) 
        ? event.filters.banca.value 
        : this.filterBanca;

      const idCompagnia = (event.filters.compagnia && event.filters.compagnia.value && event.filters.compagnia.value !== 0)
        ? event.filters.compagnia.value
        : this.filterCompagnia;

      this.filters = new ProdottiInputModel();
      if (nomeProdotto)
        this.filters.prodotto = nomeProdotto;
      if (idBanca)
        this.filters.idBanca = idBanca;
      if (idCompagnia)
        this.filters.idCompagnia = idCompagnia;
      
      this.filters.perPage = rows;

      this.currentPage = Math.ceil(event.first / rows);

      this.getProdottiCall(this.currentPage, this.filters);
  }

  /**
   *
   * @param page
   * @param filter
   */
  getProdottiCall(page, filter: ProdottiInputModel) {
    filter.page = page;
    this.prodottoService.getProdotti(filter)
    .pipe(finalize(() => this.showLoading = false))
    .subscribe(response => {
      this.showErrorDialog = false;
      this.errorMessage = undefined;
      this.totaleProdotti = response.totaleRecords
      this.prodotti = response.records;
    }, err => {
      this.showErrorDialog = true;
      this.errorMessage = err._body;
    });
  }

  /**
   *
   * @param prodottoId
   */
  eliminaProdotto(prodottoId) {
    this.confirmationService.confirm({
        message: 'Confermi la cancellazione del prodotto ?',
        accept: () => {
          this.showLoading = true;
          this.prodottoService.deleteProduct(prodottoId)
          .pipe(finalize(() => this.showLoading = false))
              .subscribe(() => {
                this.showErrorDialog = false;
                this.errorMessage = undefined;
                this.getProdottiCall(this.currentPage, this.filters);
              }, err => {
                this.showErrorDialog = true;
                this.errorMessage = err._body;
              });
        },
        reject: () => this.showLoading = false
    });
  }
}
