import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
    
    constructor(private router: Router, private authService: AuthService) { }
    
    /**
    * Determines if the route can be activated.
    * 
    * @param route - The activated route snapshot.
    * @param state - The router state snapshot.
    * @returns An observable that emits a boolean indicating if the route can be activated.
    */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isDoneLoading$.pipe(
            filter(isDone => isDone),
            switchMap(_ => this.authService.isAuthenticated$),
            tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)),
        );
    }
    
    canLoad(route: Route): boolean {
        return this.checkLoggedIn(route.path);
    }
    
    checkLoggedIn(url: string): boolean {
        if (this.authService.isAuthenticated$) {
            return true;
        }
        this.router.navigate(['']);
        return false;
    }
}
