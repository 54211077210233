import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: 'homepage', loadChildren: () => import('app/homepage/home-page.module').then(m => m.HomePageModule) },
      { path: 'errorepage', loadChildren: () => import('app/errore/errorepage.module').then(m => m.ErrorePageModule) },
      { path: 'vetrine', loadChildren: () => import('app/vetrina-home/vetrina-home.module').then(m => m.VetrinaHomeModule) },
      { path: 'operatori', loadChildren: () => import('app/operatore/operatore.module').then(m => m.OperatoreModule) },
      { path: 'prodotti', loadChildren: () => import('app/prodotto/prodotto.module').then(m => m.ProdottoModule) },
      { path: 'pagine', loadChildren: () => import('app/pagina/pagina.module').then(m => m.PaginaModule) },
      { path: 'entita', loadChildren: () => import('app/entita/entita.module').then(m => m.EntitaModule) },
      { path: '', redirectTo: 'homepage', pathMatch: 'full' },
      { path: '**', redirectTo: 'error', pathMatch: 'full'}
    ], { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
