export class FileViewModel {
  id: number
  idEntita: number
  idProdotto: number
  idPagina: number
  idTipoFile: number
  nomeFile: string
  descrizioneFile: string
  bodyPartNomeFile: string
  titoloFile: string
  isTemplate: boolean
  template: string
  daSalvare: boolean
  url: string
  virtualPath: string
}
