<ngx-loading [show]="showLoading"></ngx-loading>
<p-table [value]="pagine"
              [lazy]="true"
              [rows]="20"
              [paginator]="true"
              [totalRecords]="totaleVetrineHome"
              (onLazyLoad)="loadPagine($event)">
  <p-header><h5>Lista pagina</h5></p-header>
  <ng-template pTemplate="header">
    <tr>
      <th>Titolo Pagina</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record>
    <tr>
      <td>{{record.titoloPagina}}</td>
      <td>
        <a class="btn btn-primary" [routerLink]="['/pagine/pagina', record.id]"><i class="fa fa-pencil" aria-hidden="true"></i></a>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog>
