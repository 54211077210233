import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { OperatoreModule } from './operatore/operatore.module';

import { CommonService } from './services/common.service';
import { FileService } from './services/file.service';
import { AuthService } from './services/auth/auth.service';
import { CallbackComponent } from './callback/callback.component';
import { SharedAppModule } from './shared-app/shared-app.module';
import { PaginaModule } from './pagina/pagina.module';
import { HttpHelpersService } from './services/http-helpers.service';
import { ProdottoModule } from './prodotto/prodotto.module';


import { ToastModule, TooltipModule, MenubarModule, PanelMenuModule, MegaMenuModule, SelectButtonModule, DialogModule, ConfirmDialog } from 'primeng';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { AuthGuardService } from './guard/auth.guard';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MenubarModule,
    PanelMenuModule,
    MegaMenuModule,
    OperatoreModule,
    PaginaModule,
    SharedAppModule,
    SelectButtonModule,
    DialogModule,
    TooltipModule,
    FormsModule,
    ProdottoModule,
    ToastModule,
    OAuthModule.forRoot()
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakLoaderFactory,
      multi: true,
      deps: [AuthService]
    },
    { provide: OAuthModuleConfig, 
      useValue: {
        resourceServer: {
          allowedUrls: [environment.webApiUrl, environment.keycloakAuthorityUrl],
          sendAccessToken: true,
        }
      } 
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    CommonService,
    FileService,
    HttpHelpersService,
    AuthService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function storageFactory(): OAuthStorage {
  return localStorage;
}

export function keycloakLoaderFactory(authenticationService: AuthService): () => Promise<void> {
  return () => authenticationService.runInitialLoginSequence();
}

ConfirmDialog.prototype.appendTo = function(): void {
  if (this.appendTo) {
    if (this.appendTo === 'body')
      document.body.appendChild(this.el.nativeElement);
    else
      this.domHandler.appendChild(this.container, this.appendTo);
  }
};