<ngx-loading [show]="showLoading"></ngx-loading>
<button [routerLink]="['/operatori']" queryParamsHandling="preserve" style="margin-bottom: 5px; color:rgb(76, 76, 247); border-color: rgb(18, 214, 67);"><i class="fa fa-reply fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>Torna alla lista operatori</button>

<div class="card bg-light">
  <div class="card-header">
      <div class="form-row">
        <div class="col-md-6">{{pageTitle}}</div>
      </div>
  </div>

  <form [formGroup]="operatoreForm" id="operatoreFormId" (ngSubmit)="saveOperatore()" novalidate>
      <div class="card-body">
        <fieldset>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class=" control-label" for="nomeId"><span class="badge badge-secondary">Nome Operatore</span></label>
                <input class="form-control" id="nomeId" type="text" placeholder="Nome (obbligatorio)" formControlName="nome"/>
                <span class="help-block" *ngIf="displayMessage.nome">{{displayMessage.nome}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="cognomeId"><span class="badge badge-secondary">Cognome Operatore</span></label>
              <input class="form-control" id="cognomeId" type="text" placeholder="Cognome (obbligatorio)" formControlName="cognome" />
                <span class="help-block" *ngIf="displayMessage.cognome">{{displayMessage.cognome}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="matricolaId"><span class="badge badge-secondary">Matricola Operatore</span></label>
              <input class="form-control" id="matricolaId" type="text" placeholder="Matricola (obbligatorio)" formControlName="matricola" />
              <span class="help-block" *ngIf="displayMessage.matricola">{{displayMessage.matricola}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="entitaId"><span class="badge badge-secondary">Banca/Compagnia Operatore</span></label>
              <select class="form-control" id="entitaId" placeholder="Compagnia (obbligatorio)" formControlName="idEntita">
                <optgroup label="Banche">
                  <option *ngFor="let b of banche | async" [ngValue]="b.value">{{b.label}}</option>
                </optgroup>
                <optgroup label="Compagnie">
                  <option *ngFor="let c of compagnie | async" [ngValue]="c.value">{{c.label}}</option>
                </optgroup>
                <optgroup label="Gefit/Gefass">
                  <option *ngFor="let g of enitaAon" [ngValue]="g.value">{{g.label}}</option>
                </optgroup>
              </select>
              <span class="help-block" *ngIf="displayMessage.entita">{{displayMessage.entita}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="codiceRuiId"><span class="badge badge-secondary">Codice Rui</span></label>
              <input class="form-control" id="codiceRuiId" type="text" placeholder="CodiceRui (obbligatorio)" formControlName="codiceRui" />
              <span class="help-block" *ngIf="displayMessage.codiceRui">{{displayMessage.codiceRui}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="emailId"><span class="badge badge-secondary">Email</span></label>
              <input class="form-control" id="emailId" type="text" placeholder="email (obbligatorio)" formControlName="email" />
              <span class="help-block" *ngIf="displayMessage.email">{{displayMessage.email}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="ruoloId"><span class="badge badge-secondary">Ruolo Operatore</span></label>
              <select class="form-control" id="ruoloId" placeholder="Ruolo (obbligatorio)" formControlName="ruolo">
                <option [ngValue]="null" disabled>Ruolo</option>
                <option *ngFor="let r of ruoli | async" [ngValue]="r.id">{{r.name}}</option>
              </select>
            </div>
            <div class="form-inline form-group">
              <div class="form-group">
                <label class="control-label"  attr.for="abilitaIsvapId" style="margin-bottom: 0.5rem;margin-left: 10%;"><span class="badge badge-secondary">Abilita Ivass </span></label>
                <div class="col-md-12" style="margin-left: 0%;">
                  <p-toggleButton formControlName="abilitatoIsvap" onIcon="fa-check-square-o" offIcon="fa-square"></p-toggleButton>
                </div>
              </div>
              <div class="form-group" >
                <label class="control-label" attr.for="abilitatoMifidId" style="margin-bottom: 0.5rem;margin-left: 10%;"><span class="badge badge-secondary">Abilita Mifid </span></label>
                <div class="col-md-8">
                  <p-toggleButton formControlName="abilitatoMifid" onIcon="fa-check-square-o" offIcon="fa-square"></p-toggleButton>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label" attr.for="eliminatoId" style="margin-bottom: 0.5rem;margin-left: 10%;"><span class="badge badge-secondary">Eliminato </span></label>
                <div class="col-md-8">
                  <p-toggleButton formControlName="eliminato" onIcon="fa-check-square-o" offIcon="fa-square"></p-toggleButton>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!showOnlyRegistry">
            <h5 style="bottom: 0.5rem;">Gestione claims.</h5>
          
            <div class="form-row" style="border: 1px solid rgb(219, 219, 219);margin-bottom: 1rem;border-color: brown;">
              <div class="form-group col-md-5" style="margin-left: 26px;">
                <label class="control-label" attr.for="claimsnameId"><span class="badge badge-secondary">Tipo Claim </span></label>
                <select class="form-control" id="claimsnameId" placeholder="Compagnia (obbligatorio)" #claimsnames>
                  <optgroup>
                    <option *ngFor="let r of claimsname | async" [ngValue]="r.value">{{r.label}}</option>
                  </optgroup>
                </select>
              </div>
              <div class="form-group col-md-5">
                <label class="control-label" attr.for="valoredelclaimId"><span class="badge badge-secondary">Valore del claim</span></label>
                <input class="form-control" id="valoredelclaimId" type="text" placeholder="inserire il valore del claim" #valoredelclaim formControlName="valoredelclaim" />
              </div>
              <div class="" style="padding-top: 2%;margin-right: 1%;">
                <button class="btn btn-primary" id="button-3" (click)="onInsertClaims(claimsnames.value,valoredelclaim.value)">Insert</button>
              </div>

              <div class="claimsTable">
                <p-table #dt [value]="OpClaimsList"
                  [rows]="10"  
                  pageLinks="3"
                  [paginator]="true"
                  [rowsPerPageOptions]="[5,10,15,20]">
                  <p-header><h5>Claims</h5></p-header>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Tipo</th>
                      <th>Valore</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-record>
                    <tr>
                      <td>{{record.claimType}}</td>
                      <td>{{record.claimValue}}</td>
                      <td>
                        <a class="btn btn-danger" (click)="onCancellaClaims(record.userId, record.claimType)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                      </td>  
                    </tr>
                  </ng-template>
                </p-table> 
              </div> 

            </div>

            <h5>Gestione abilitazioni.</h5>

            <div class="form-row" style="border: 1px solid rgb(219, 219, 219);margin-bottom: 1rem;border-color: brown;">
              <div class="form-group col-md-6" style="margin-left: 26px;">
                <label class="control-label" attr.for="prodottoId"><span class="badge badge-secondary">Elenco dei prodotti </span></label>
                <select class="form-control" id="prodottoId"  placeholder="Compagnia (obbligatorio)" formControlName="prodottovalue" #prodottovalue>
                  <optgroup>
                    <option *ngFor="let b of ProdottoList" value="{{ b.codiceTariffaBanca }}" >
                      {{b.nome}}
                    </option>
                  </optgroup>
                </select>
              </div>
              <div class="" style="padding-top: 2%;">
                <button class="btn btn-primary" type="submit" id="button-3" (click)="addAbilitazione(prodottovalue.value)">Insert</button>
              </div>

              <div class="abilitazioniTable">
                <p-table #dt [value]="AbilitazioniList"
                  [rows]="10"  
                  pageLinks="3"
                  [paginator]="true"
                  [rowsPerPageOptions]="[5,10,15,20]"
                  [totalRecords]="totaleAbilitazioni">
                  <p-header><h5>Abilitazione List</h5></p-header>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Compagnia</th>
                      <th>Nome prodotto</th>
                      <th>Codice tariffa</th>
                      <th>Abilitato</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-record>
                    <tr>
                      <td>{{record.compagnia}}</td>
                      <td>{{record.nome}}</td>
                      <td>{{record.codiceTariffa}}</td>
                      <td>{{record.abilitato}}</td>
                      <td>
                        <a class="btn btn-danger" (click)="cancellaAbilitazione(record.id)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table> 
              </div> 
            </div>
          </div>

        </fieldset>
      </div>
  </form>
  
  <div class="card-footer bg-secondary" style="text-align: center;">
    <button type="submit" form="operatoreFormId" class="btn btn-success" [disabled]="!operatoreForm.valid" >Salva</button>
  </div>
</div>

<br />

<p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog>
