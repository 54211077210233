import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { environment } from '../../environments/environment';
import { Prodotto } from './../prodotto/prodotto';
import { ProdottiInputModel } from 'app/prodotto/models/prodotti-input.model';
import { HttpHelpersService } from '../services/http-helpers.service';

@Injectable()
export class ProdottoService {

    constructor(private http: HttpClient, private httpHelpers: HttpHelpersService) { }



    /**
     *
     * @param banca
     * @param compagnia
     * @param codicetariffaBanca
     * @param codiceTariffaCompagnia
     */
    checkProdottoExists(banca, compagnia, codicetariffaBanca, codiceTariffaCompagnia) {
        const filters = {
          idBanca: banca,
          idCompagnia: compagnia,
          codicetariffaBanca: codicetariffaBanca,
          codiceTariffaCompagnia: codiceTariffaCompagnia
        }
        let httpParams = this.httpHelpers.setHttpParams(filters);
        return this.http.get(environment.webApiUrl + 'api/prodotti/checkProdottoExists', { params: httpParams });
    }

    /**
     *
     * @param filter
     */
    getProdotti(filters: ProdottiInputModel): Observable<any>  {
      let httpParams = this.httpHelpers.setHttpParams(filters);
      return this.http.get(environment.webApiUrl + 'api/prodotti/getProdotti', { params: httpParams });
    }

    /**
     *
     * @param id
     */
    getProdotto(id: number): Observable<any>  {
      const filters = { id: id }
      let httpParams = this.httpHelpers.setHttpParams(filters);
      return this.http.get(environment.webApiUrl + 'api/prodotti/getProdotto', { params: httpParams });
    }

    /**
     *
     */
    getProdottiBundle(idBanca: number): Observable<any> {
      const filters = { idBanca: idBanca }
      let httpParams = this.httpHelpers.setHttpParams(filters);
      return this.http.get(environment.webApiUrl + 'api/prodotti/getProdottiBundle', { params: httpParams });
    }



    /**
     *
     * @param prodotto
     */
    saveProdotto(prodotto: Prodotto): Observable<any> {
      if (_.isNil(prodotto.id) || prodotto.id === 0)
          return this.createProduct(prodotto);
      return this.updateProduct(prodotto);
    }

    /**
     *
     * @param product
     * @param options
     */
    private createProduct(product: Prodotto): Observable<Prodotto> {
      let headers = this.httpHelpers.setHttpHeaders();
      const url = `${environment.webApiUrl}api/prodotti/createProdotto`;
      return this.http.post<Prodotto>(url, product, { headers: headers });
    }

    /**
     *
     * @param product
     * @param options
     */
    private updateProduct(product: Prodotto): Observable<Prodotto> {
      let headers = this.httpHelpers.setHttpHeaders();
      const url = `${environment.webApiUrl}api/prodotti/updateProdotto/${product.id}`;
      return this.http.put<Prodotto>(url, product, { headers: headers });
    }

    /**
     *
     * @param idProdotto
     */
    public deleteProduct(idProdotto: number): Observable<Prodotto> {
      let headers = this.httpHelpers.setHttpHeaders();
      const url = `${environment.webApiUrl}api/prodotti/deleteProdotto/${idProdotto}`;
      return this.http.delete<Prodotto>(url, { headers: headers });
    }
}
