import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as moment from 'moment-timezone';

import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Injectable()
export class CommonService {

  urlBaseApiFile = environment.webApiUrl + `/api/files/downloadFile/`;

  constructor(private http: HttpClient) { }

  getPayload(payloadRaw: any) {
    const params: {} = {};

    const keysParams = Object.keys(payloadRaw);

    for (const entry of keysParams) {
      if (payloadRaw[entry] instanceof Date)
        params[entry] = this.prepareDate(payloadRaw[entry]);
      else
        params[entry] = payloadRaw[entry];
    }

    return params;
  }

  prepareDate(date: Date) {
    return moment.tz(date, 'Europe/Rome').format('YYYY-MM-DDTHH:mm:ss');
  }

  getEntita(): Observable<any>  {
    return this.http.get(environment.webApiUrl + 'api/entita/getEntita');
  }

  getBanche(): Observable<any>  {
    return this.http.get(environment.webApiUrl + 'api/entita/getBanche');
  }

  getCompagnie(): Observable<any>  {
    return this.http.get(environment.webApiUrl + 'api/entita/getCompagnie');
  }

  getRuoli(): Observable<any>  {
    return this.http.get(environment.webApiUrl + 'api/operatori/getRuoli');
  }

  getClaimsNames(): Observable<any> {
    return this.http.get(environment.webApiUrl + 'api/operatori/getClaimsName');
  }

  getTipiFile() {
    let tipiFile = [];
    tipiFile.push({label: 'Documento (pdf, doc, xls, ecc.ecc)', value: 1});
    tipiFile.push({label: 'Documento Cliente Asti (pdf, doc, xls, ecc.ecc)', value: 11});
    tipiFile.push({label: 'Documento PostVendita (pdf, doc, xls, ecc.ecc)', value: 4});
    tipiFile.push({label: 'Documento Sinistri (pdf)', value: 6});
    tipiFile.push({label: 'Documento da Archiviare (pdf)', value: 7});
    tipiFile.push({label: 'Documento Non Visibile (pdf)', value: 10});
    tipiFile.push({label: 'Immagine home page (png, jpg)', value: 3});
    tipiFile.push({label: 'Logo Principale (png, jpg)', value: 2});
    tipiFile.push({label: 'Icona Prodotto (png, jpg)', value: 5});
    tipiFile.push({label: 'Grafica Prodotto (png, jpg)', value: 8});
    tipiFile.push({label: 'Da Cestinare (png, jpg)', value: 99});
    return tipiFile;
  }

  getTipiNews() {
    let tipiNews = [];
    tipiNews.push({label: 'News', value: 1});
    tipiNews.push({label: 'Comunicazione', value: 2});
    return tipiNews;
  }

  /**
    *
    */
  downloadFile(idFile: number, nomefile?: string) {
    let fileType: string;
    let extFile = nomefile.slice(-3);
    let extFiles = nomefile.slice(-4);

    if (extFile === 'pdf' || extFile === 'doc' || extFile === 'xls') {
      fileType = 'application/' + extFile;
    } 
    if (extFiles === 'docs' || extFiles === 'xlsx' || extFiles === 'pptx') {
      fileType = 'application/' + extFiles;
    } else {
      fileType = 'image/' + extFile
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
      } ), 
      responseType: fileType
    };
  
    this.http.get(environment.webApiUrl + `/api/files/downloadFile/${idFile}`, { headers: httpOptions.headers, responseType: 'blob'}).subscribe((x) => {console.log(x);
      let blob = new Blob([x], { type: fileType});
      let url = window.URL.createObjectURL(blob);
     
      let createdElement  = document.createElement('a');
      createdElement.href = url;
      createdElement.download = nomefile;
      createdElement.click();

      createdElement.remove();
    });
  }

  /**
   *
   * @param error
   */
  public handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error);
    return Observable.throw(error.json() || 'Server error');
  }

  /**
   *
   */
  getSources(): SelectItem[] {
    let sources = [];
        sources.push({label: 'Tsunami 3 (TS3)', value: 'TS3'});
        sources.push({label: 'Tsunami 1 (TS1)', value: 'TS1'});
        sources.push({label: 'Cedacri (CED)', value: 'CED'});
        sources.push({label: 'Compagnia (COM)', value: 'COM'});

    return sources;
  }

  /**
   *
   */
  getTemplatesFile(): SelectItem[] {
    let templatesFile = [];
    
    templatesFile.push({label: 'Nessun template',  value: null});
    templatesFile.push({label: 'F. Informativo Tag', value: 'FI'});
    templatesFile.push({label: 'Allegato 4', value: 'ALLEGATO4'});
    templatesFile.push({label: 'Allegato 3', value: 'ALLEGATO3'});
    templatesFile.push({label: 'Questionario Demand and Needs', value: 'QIDD'});
    
    templatesFile.push({label: 'Allegato E', value: 'ALLEGATOE'});
    templatesFile.push({label: 'Allegato E IBIPs', value: 'ALLEGATOE_IBIPS'});
    templatesFile.push({label: 'Informativa IBIPs', value: 'ALLEGATO3_IBIPS'});

    templatesFile.push({label: 'Esito consulenza Generico', value: 'EIDD'});
    templatesFile.push({label: 'Esito consulenza Positivo', value: 'EIDDP'});
    templatesFile.push({label: 'Esito consulenza Positivo (No Emissione)', value: 'EIDDPNOEMI'});
    templatesFile.push({label: 'Esito consulenza Negativo', value: 'EIDDN'});
    templatesFile.push({label: 'Esito consulenza Prodotti IBIPs', value: 'EIDD_IBIPS'});
    templatesFile.push({label: 'Esito consulenza Prodotti Erbavoglio', value: 'EIDD_EBV'});

    templatesFile.push({label: 'Questionario Adeguatezza', value: 'ADE'});
    templatesFile.push({label: 'Modulo Richiesta Emissione CoverFax', value: 'COVERFAX'});
    templatesFile.push({label: 'Modulo Richiesta Preventivo CoverFax', value: 'PREV_COVERFAX'});
    templatesFile.push({label: 'Informazioni KID', value: 'KID'});
    templatesFile.push({label: 'Informazioni Chiave Aderente', value: 'INFOCHIAVE'});

    return templatesFile;
  }

  /**
   *
   */
  getSaveFileOptions(): SelectItem[] {
    let toSaveOptions = [];
        toSaveOptions.push({label: 'Solo Download',  value: false});
        toSaveOptions.push({label: 'Da Salvare', value: true});

    return toSaveOptions;
  }

  /**
   *
   */
  getVersioniPiattaforme(): Observable<any>  {
    return this.http.get(environment.webApiUrl + 'api/prodotti/getVersioniPiattaforme');
  }

}
