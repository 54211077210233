import { Component, OnInit, Output, OnDestroy, ViewChild, ElementRef, ViewChildren, ViewContainerRef, ComponentFactoryResolver, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup, FormControlName, Validators } from '@angular/forms';
import { ActivatedRoute, Router  } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { ProdottoService } from '../prodotto.service';
import { CommonService } from './../../services/common.service';
import { FileService } from './../../services/file.service';


import { Prodotto } from '../prodotto';
import { FileViewModel } from './../../models/FileViewModel';
import { DialogImplementation } from '../../interfaces/DialogImplementation';
import { DisplayMessageModel } from 'app/models/DisplayMessageModel';

import { ModalProdottoEditComponent } from '../modal-prodotto-edit/modal-prodotto-edit';
import { SelectItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-prodotto-edit',
  templateUrl: './prodotto-edit.component.html',
  styleUrls: ['./prodotto-edit.component.css'],
  providers: [MessageService]
})
export class ProdottoEditComponent implements OnInit, OnDestroy, DialogImplementation {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('modalFileProdottoEdit', { read: ViewContainerRef, static: true }) modalFileProdottoEdit: ViewContainerRef;

  @Output() setLoader: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  
  isTestEnviroment: boolean;
  
  pageTitle = '';
  displayProdottoModal = false;

  idEntita: number;

  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  idProdotto: number;
  prodottoForm: FormGroup;
  prodotto: Prodotto;
  filieCount: number;

  compagnie: Observable<SelectItem[]>;
  banche: Observable<SelectItem[]>;

  sources: SelectItem[];
  versions: SelectItem[];
  versioniPiattaforme: Observable<SelectItem[]>;
  tipiFile: SelectItem[];
  prodottiBundle:  Observable<SelectItem[]>;
  templatesFile: SelectItem[];
  toSaveOptions: SelectItem[];

  files: FileViewModel[] = [];
  file: FileViewModel = new FileViewModel();
  tipofileList: any[];

  titoloFile: string;
  tipoFileDescrizione: string;
  tipoFileTemplate: string;
  tipoFileTemplateValue: number;
  tipoFileSaveOptions: string;
  tipoFileSaveOptionValue: string;
  idFile: number;
  tipoId: number;
  modalUpload: boolean;

  private sub: Subscription;

  // Use with the generic validation message class
  displayMessage = new DisplayMessageModel();

  prodottoExists: boolean;

  uploadedFiles: any[] = [];
  reloadOnlyFiles: boolean;

  stateOptions: SelectItem[];


  
  /**
   * CTOR
   * @param fb
   * @param route
   * @param router
   * @param prodottoService
   * @param commonService
   */
  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              public router: Router,
              private prodottoService: ProdottoService,
              private fileService: FileService,
              private commonService: CommonService,
              private messageService: MessageService,
              public componentFactoryResolver: ComponentFactoryResolver ) {


  }

  /**
   *
   */
  ngOnInit() {
    this.showLoading = true;
    this.idProdotto = this.route.snapshot.params['id'];
    console.log('idProdotto', this.idProdotto);
    const validationFieldsRules = this.getValidationFieldsRules();
    this.prodottoForm = this.fb.group(validationFieldsRules);

    this.sources = this.commonService.getSources();
    this.tipiFile = this.commonService.getTipiFile();
    this.templatesFile = this.commonService.getTemplatesFile();
    this.toSaveOptions = this.commonService.getSaveFileOptions();
    this.banche = this.commonService.getBanche();
    this.compagnie = this.commonService.getCompagnie();
    this.versioniPiattaforme = this.commonService.getVersioniPiattaforme();

    this.sub = this.route.params.subscribe(params => this.onRouteChange(params));
  }

  createModalGestioneFile(file: FileViewModel) {
    this.displayProdottoModal = true;
    const modalFileProdottoEditCompFactory = this.componentFactoryResolver.resolveComponentFactory(ModalProdottoEditComponent);
    const modalFileProdottoEditComponent = this.modalFileProdottoEdit.createComponent(modalFileProdottoEditCompFactory);
    modalFileProdottoEditComponent.instance.displayModal = this.displayProdottoModal;         
    modalFileProdottoEditComponent.instance.file = file;   
    //modalFileProdottoEditComponent.instance.prodotto = prodotto;   
    this.setLoader.emit(false);
  }


  /**
   *
   * @param params
   */
  private onRouteChange(params) {
    this.showLoading = false;
    
    if (this.idProdotto > 0) {
      this.getProdotto(this.idProdotto);
    }

  }
  

  /**
   *
   */
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   *
   * @param id
   */
  getProdotto(id: number): void {
    this.showLoading = true;
    this.prodottoService.getProdotto(id).subscribe(
      (prodotto: Prodotto) => this.onProdottoRetrieved(prodotto),
      (error: any) => this.httpErrorMessage(error, 'Errore nel recupero dati prodotto')
    );
  }

  /**
   *
   * @param prodotto
   */
  onProdottoRetrieved(prodotto: Prodotto): void {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;

    if (prodotto == null)
      prodotto = new Prodotto();

    this.prodotto = prodotto;

    this.ArrayForDatagrid();

    if (this.reloadOnlyFiles) {
      this.reloadOnlyFiles = false;
      return;
    }

    this.prodotto.isBundle = this.prodotto.isBundle ? true : false // this.idProdotto === 0 ||
    this.prodotto.hasPostVendita = this.prodotto.hasPostVendita ? true : false

    if (this.prodottoForm)
        this.prodottoForm.reset();

    // Update the data on the form
    // nomeForm.patchValue : Patches the value of the {@link FormGroup}. It accepts an object with control names as keys, and will do its best to match the values to the correct controls in the group.
    this.prodottoForm.patchValue({
        nome: this.prodotto.nome,
        codiceTsunami: this.prodotto.codiceTsunami,
        idBanca:  this.prodotto.idBanca,
        codiceTariffaBanca: this.prodotto.codiceTariffaBanca,
        idCompagnia: this.prodotto.idCompagnia,
        codiceTariffaCompagnia: this.prodotto.codiceTariffaCompagnia,
        descrizione: this.prodotto.descrizione,
        descrizioneCommerciale: this.prodotto.descrizioneCommerciale,
        tipologiaCopertura: this.prodotto.tipologiaCopertura,
        targetRiferimento: this.prodotto.targetRiferimento,
        livelloCopertura: this.prodotto.livelloCopertura,
        provvigioni: this.prodotto.provvigioni,
        campagnaVendita: this.prodotto.campagnaVendita,
        abilitato: this.prodotto.abilitato,
        emissioneAbilitata: this.prodotto.emissioneAbilitata,
        sbloccoOnere: this.prodotto.sbloccoOnere,
        version: this.prodotto.version,
        source: this.prodotto.source,
        comparatoreErbavoglio: this.prodotto.comparatoreErbavoglio,
        isBundle: this.prodotto.isBundle,
        hasPostVendita: this.prodotto.hasPostVendita,
        idProdottoBundle: this.prodotto.idProdottoBundle
    });
    
    this.disableFields();

    this.prodottiBundle = this.prodottoService.getProdottiBundle(this.prodotto.idBanca);
  }

  // il metodo per popolare la dataview.   
  private ArrayForDatagrid() {

    let m = new Map();
    let n = new Map();

    this.files = this.prodotto.files;
    
    this.tipiFile.forEach(function(x) {m.set(x.value, x)});
    
    this.files.forEach(function(y) {
        let existe = m.get(y.idTipoFile);
        if (existe != null)
          n.set(y.descrizioneFile = existe.label, y);
        // if (y.descrizioneFile === null) {
        //   Object.assign(existe, y);
        // }
    });
  }

  /**
   * Open popup
   */
  openPopup(file: FileViewModel) {
    this.createModalGestioneFile(file); //prodotto, file
  }



  /**
   * onChangeTypeFile per cambiare tipo nel dropdown
   */
  onChangeTypeFile(event) {
    this.tipoFileDescrizione = event.value.label;
  }

  onChangetemplate(event) {
    this.tipoFileTemplate = event.value.label;
    this.tipoFileTemplateValue = event.value;
  }

  onChangeSaveOptions(event) {
    this.tipoFileSaveOptions = event.value.label;
  }

  /**
   *
   */
  public onFileUploadedSuccess() {
    this.reloadOnlyFiles = true;
    this.getProdotto(this.idProdotto);
    this.messageService.add({severity: 'success', summary: 'File caricato', detail: ''});
  }

  /**
   *
   */
  public onFileUploadedError() {
    this.messageService.add({severity: 'error', summary: 'Errore nel caricamento del file', detail: ''});
  }

  /**
   *
   */
  saveProdotto() {
    if (this.prodottoForm.dirty && this.prodottoForm.valid) {
      let prodotto = Object.assign({}, this.prodotto, this.prodottoForm.value);
      delete prodotto.files; //ridurre le dimensioni per HttpRequest 
      this.showLoading = true;
      this.prodottoService.saveProdotto(prodotto).subscribe(
        (response) => {
          this.onSaveComplete();
          
          this.router.navigate(['/prodotti/prodotto', response]);
        },
        (error: any) => this.httpErrorMessage(error, 'Errore nel salvataggio dei dati prodotto'));
    }
  }

  /**
   *
   */
  onSaveComplete(): void {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.messageService.add({severity: 'success', summary: 'Modifica registrata', detail: ''});
  }

  /**
   *
   * @param titoloFile
   * @param file
   */
  //public aggiornaFile(titoloFile, tipoFile, template, daSalvare, file: FileViewModel)
  public aggiornaFile(file: FileViewModel) {
    this.showLoading = true;
    file.titoloFile = this.titoloFile;
    file.idTipoFile = this.tipoFileTemplateValue;
    file.template = this.tipoFileTemplate === 'null' ? null : this.tipoFileTemplate;
    file.daSalvare = this.tipoFileTemplate === 'null' ? null : JSON.parse(this.tipoFileSaveOptionValue);

    this.fileService.updateFile(file).subscribe(
      () => this.onUpdatedFile(),
      (error: any) => this.httpErrorMessage(error, 'Errore nell\' aggiornamento del file'));
    
    this.showLoading = false;
  }

  /**
   *
   */
  onUpdatedFile() {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.messageService.add({severity: 'success', summary: 'File aggiornato', detail: ''});
  }


  /**
   *
   */
  onFileEliminated(file) {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;

    const index = this.files.findIndex(n => n.nomeFile === file.nomeFile);
    if (index !== -1) {
      this.files.splice(index, 1);
    }

    this.messageService.add({severity: 'success', summary: 'File eliminato', detail: ''});
    this.getProdotto(this.idProdotto);
  }

  /**
   *
   * @param filename
   */
  public eliminaFile(file: FileViewModel) {
    this.showLoading = true;
    this.fileService.deleteFile(file).subscribe(
      () => this.onFileEliminated(file),
      (error) => this.httpErrorMessage(error, 'Errore nell\' eliminazione del file'));
  }

  /**
   *
   */
  public downloadFile(idFile: number, nomefile: string) {
      this.commonService.downloadFile(idFile, nomefile);
  }

  /**
   *
   */
  private getValidationFieldsRules() {
    return {
      nome: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      codiceTsunami: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(6)]],
      idBanca: [{ value: null}, Validators.required],
      codiceTariffaBanca: ['', [Validators.required, Validators.maxLength(20)]],
      idCompagnia: [{ value: null}, Validators.required],
      codiceTariffaCompagnia : ['', [Validators.required,  Validators.maxLength(20)]],
      descrizione: '',
      descrizioneCommerciale: '',
      tipologiaCopertura : '',
      targetRiferimento: '',
      livelloCopertura: null,
      provvigioni: '',
      campagnaVendita: '',
      source: '',
      version: '',
      abilitato: true,
      emissioneAbilitata: true,
      sbloccoOnere: false,
      comparatoreErbavoglio: false,
      isBundle: false,
      hasPostVendita: false,
      idProdottoBundle: null
    }
  }

  /**
   *
   */
  private getValidationMessages() {
    return {
        nome: {
          required: 'Obbligatorio',
          minlength: 'Must be at least three characters.',
          maxlength: 'Cannot exceed 50 characters.'
        },
        codiceTsunami: {
          required: 'Obbligatorio',
          minlength: 'Must be at least 6 characters.',
          maxlength: 'Cannot exceed 6 characters.'
        },
        banca: {
          required: 'Obbligatorio'
        },
        compagnia: {
          required: 'Obbligatorio'
        },
        codiceTariffaBanca : {
          required: 'Obbligatorio'
        },
        codiceTariffaCompagnia: {
          required: 'Obbligatorio'
        }
    };
  }

  /**
   *
   */
  private disableFields() {
    this.prodottoForm.controls.idBanca.disable();
    this.prodottoForm.controls.codiceTariffaBanca.disable();
    this.prodottoForm.controls.idCompagnia.disable();
    this.prodottoForm.controls.codiceTariffaCompagnia.disable();
  }

  /**
   *
   */
  private httpErrorMessage(error: any, message: string) {
    this.showLoading = false;
    this.showErrorDialog = true;
    this.errorMessage = error._body;
    this.messageService.add({severity: 'error', summary: message, detail: ''});
  }
}
