import { Injectable } from "@angular/core";
@Injectable()
export class OperatoreConstants {

  tipoEntita: any;
  tipoRuolo: any;

  /**
   *
   */
  constructor() {
      this.tipoEntita = {
        banca: 'B',
        compagnia: 'C',
        gef: 'G'
      }

      this.tipoRuolo = {
        filialista: 'Filialista',
        capoarea: 'CapoArea',
        administrator: 'Administrator'
      }
  }

}
