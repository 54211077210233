import { PaginationResult } from './../models/paginationResult';

export class PaginaInputModel {
  id: number;
  idEntita: number;
  titoloPagina: string;
  contenuto: string;
  permalink: string;
  tags: string;
  isOnlyPdf: boolean;
  files: any[];
}

export class PaginePagination implements PaginationResult {
  totaleRecords: number;
  records: PaginaInputModel[];
}


export class PaginaViewModel {
  id: number;
  idEntita: number;
  titoloPagina: string;
  contenuto: string;
  permalink: string;
  tags: string;
  isOnlyPdf: boolean;
  
  public constructor(init?: Partial<PaginaViewModel>) {
    Object.assign(this, init);
  }
}
