<ngx-loading [show]="showLoading"></ngx-loading>
 <p-table 
    [value]="operatori"
    [lazy]="true"
    [responsive]="true"
    [paginator]="true"
    [totalRecords]="totaleOperatori"
    (onLazyLoad)="loadOperatori($event)"
    [globalFilterFields]="['nome', 'cognome', 'matricola']" styleClass="ui-table-customers" [rowHover]="true"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="[15,25,50]"
    [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [filterDelay]="500"
    #dt>
    <ng-template pTemplate="caption">
        <div class="ui-table-globalfilter-container">
            <input type="text" pInputText placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')">
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-col>
        <tr>
            <th>Nome</th>
            <th>Cognome</th>
            <th>Matricola</th>
            <th>Tipo Entita</th>
            <th>Eliminato</th>
            <th></th>
        </tr>
        <tr>
            <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'nome', 'contains')" placeholder="Search">
            </th>
            <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'cognome', 'contains')" placeholder="Search">
            </th>
            <th>
                <input pInputText type="text" (input)="dt.filter($event.target.value, 'matricola', 'contains')" placeholder="Search">
            </th>
            <th>
                <p-dropdown [options]="tipiEntita" [(ngModel)]="filterEntityType" filter="filter" [style]="{'width':'100%'}" (onChange)="dt.filter($event.value,col.field,col.filterMatchMode)" styleClass="ui-column-filter"></p-dropdown>
            </th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
        <tr>
            <td>{{record.nome}}</td>
            <td>{{record.cognome}}</td>
            <td>{{record.matricola}}</td>
            <td>{{record.tipoEntita}}</td>
            <td>{{record.eliminato}}</td>
            <td>
                <a class="btn btn-primary" [routerLink]="['/operatori/operatore', record.id]" *ngIf="record.tipoEntita != 'G'"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                <a class="btn btn-danger" (click)="eliminaOperatore(record.id)"><i class="fa fa-trash" aria-hidden="true"></i></a>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" global="false" appendTo="body"></p-confirmDialog>
<!-- <p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog> -->

