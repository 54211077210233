<ngx-loading [show]="showLoading"></ngx-loading>
<button [routerLink]="['/prodotti']" queryParamsHandling="preserve" style="margin-bottom: 5px; color:rgb(76, 76, 247); border-color: rgb(18, 214, 67);"><i class="fa fa-reply fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>Torna alla lista prodotti</button>

<div class="card bg-light">
    <div class="card-header">
      {{pageTitle}}
    </div>

    <form [formGroup]="prodottoForm" id="prodottoFormId" (ngSubmit)="saveProdotto()" novalidate>
      <div class="card-body">
        <fieldset>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" for="nomeId"><span class="badge badge-secondary">Nome Prodotto</span></label>
              <input type="text" id="nomeId" class="form-control" placeholder="Nome (obbligatorio)" formControlName="nome" />
              <span class="help-block" *ngIf="displayMessage.nomeProdotto">{{displayMessage.nomeProdotto}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" for="codiceTsunamiId"><span class="badge badge-secondary">Codice Tsunami (0000xy)</span></label>
              <input type="text" id="codiceTsunamiId" class="form-control" placeholder="Codice Tsunami (obbligatorio)" formControlName="codiceTsunami" />
              <span class="help-block" *ngIf="displayMessage.codiceTsunami">{{displayMessage.codiceTsunami}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="bancaId"><span class="badge badge-secondary">Banca</span></label>
              <p-dropdown [options]="banche | async" [style]="{'width':'100%'}" placeholder="Seleziona Banca (obbligatorio)" formControlName="idBanca"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.entitaBanca">{{displayMessage.entitaBanca}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="codiceTariffaBancaId"><span class="badge badge-secondary">Codice Tariffa Banca</span></label>
              <input type="text" id="codiceTariffaBancaId" class="form-control" placeholder="Codice Tariffa Banca (obbligatorio)" formControlName="codiceTariffaBanca" />
              <span class="help-block" *ngIf="displayMessage.codiceTariffaBanca">{{displayMessage.codiceTariffaBanca}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="compagniaId"><span class="badge badge-secondary">Compagnia</span></label>
              <p-dropdown [options]="compagnie | async" [style]="{'width':'100%'}" placeholder="Seleziona Compagnia (obbligatorio)" formControlName="idCompagnia"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.entitaCompagnia">{{displayMessage.entitaCompagnia}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="codiceTariffaCompagniaId"><span class="badge badge-secondary">Codice Tariffa Compagnia</span></label>
              <input type="text" id="codiceTariffaCompagniaId" class="form-control" placeholder="codiceTariffaCompagnia (obbligatorio)" formControlName="codiceTariffaCompagnia" />
              <span class="help-block" *ngIf="displayMessage.codiceTariffaCompagnia">{{displayMessage.codiceTariffaCompagnia}}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group p-col-12 p-md-3">
              <label for="livelloCopertura" class="control-label"><span class="badge badge-secondary">Livello Copertura</span></label>
              <p-inputNumber formControlName="livelloCopertura" mode="decimal" [showButtons]="true" [min]="0" [max]="5">
              </p-inputNumber>
            </div>
          </div>

          <div class="alert alert-danger" *ngIf="prodottoExists" role="alert">
            Prodotto già censito
          </div>

          <div class="form-row">
            <div class="form-group">
              <label class="col-md-6 control-label" attr.for="abilitatoId"><span class="badge badge-secondary">Abilitato</span></label>
              <div class="col-md-6" style="margin-left: 0%;">
                <p-toggleButton formControlName="abilitato" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-6 control-label" attr.for="abilitatoId"><span class="badge badge-secondary">Emissione Abilitata</span></label>
              <div class="col-md-6">
                <p-toggleButton formControlName="emissioneAbilitata" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-6 control-label" attr.for="sbloccoOnereId"><span class="badge badge-secondary">Sblocco dell'Onere</span></label>
              <div class="col-md-6">
                <p-toggleButton formControlName="sbloccoOnere" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-8 control-label" attr.for="comparatoreErbavoglioId"><span class="badge badge-secondary">Comparatore Erbavoglio</span></label>
              <div class="col-md-4">
                <p-toggleButton formControlName="comparatoreErbavoglio" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-8 control-label" attr.for="isBundleId"><span class="badge badge-secondary">E' un Prodotto Bundle?</span></label>
              <div class="col-md-4">
                <p-toggleButton formControlName="isBundle" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-6 control-label" attr.for="hasPostVenditaId"><span class="badge badge-secondary">PostVendita</span></label>
              <div class="col-md-6">
                <p-toggleButton formControlName="hasPostVendita" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right"></p-toggleButton>
              </div>
            </div>

            <div class="form-group" *ngIf="!prodotto?.isBundle" [ngClass]="{'has-error': displayMessage.prodottoBundle }">
              <label class="control-label" for="prodottoBundleId"><span class="badge badge-secondary">Prodotto Bundle Associato</span></label>
              <p-dropdown [options]="prodottiBundle | async"  [style]="{'width':'100%'}" placeholder="Associa Prodotto Bundle" formControlName="idProdottoBundle"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.prodottoBundle">{{displayMessage.prodottoBundle}}</span>
            </div>
          </div>
          
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="source"><span class="badge badge-secondary">Appartenenza</span></label>
              <p-dropdown [options]="sources" [style]="{'width':'100%'}" placeholder="Appartenenza (obbligatorio)" formControlName="source"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.source">{{displayMessage.source}}</span>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label" attr.for="version"><span class="badge badge-secondary">Versione Piattaforma</span></label>
              <p-dropdown [options]="versioniPiattaforme | async" [style]="{'width':'100%'}"  placeholder="Versione Piattaforma (obbligatorio)" formControlName="version"></p-dropdown>
              <span class="help-block" *ngIf="displayMessage.version">{{displayMessage.version}}</span>
            </div>
          </div>

          <div class="form-group">
              <label class="control-label" attr.for="descrizioneId"><span class="badge badge-secondary">Nome Prodotto Bundle:</span></label>
              <textarea class="form-control" id="descrizioneId" placeholder="Descrizione" formControlName="descrizione"></textarea>
          </div>

          <div class="form-group">
            <label class="control-label" attr.for="descrizioneCommercialeId"><span class="badge badge-secondary">Descrizione Commerciale</span></label>
            <p-editor id="descrizioneCommercialeId" maximizable="true" formControlName="descrizioneCommerciale" ></p-editor>
          </div>

          <div class="form-group">
            <label class="control-label" attr.for="tipologiaCoperturaId"><span class="badge badge-secondary">Tipologia Copertura</span></label>
            <p-editor id="tipologiaCoperturaId" maximizable="true" formControlName="tipologiaCopertura"></p-editor>
          </div>

          <div class="form-group">
            <label class="control-label" attr.for="targetRiferimentoId"><span class="badge badge-secondary">Target di riferimento</span></label>
            <p-editor id="targetRiferimentoId" maximizable="true" formControlName="targetRiferimento"></p-editor>
          </div>

          <div class="form-group">
            <label class="control-label" attr.for="provvigioniId"><span class="badge badge-secondary">Provvigioni</span></label>
            <p-editor id="provvigioniId" maximizable="true" formControlName="provvigioni"></p-editor>
          </div>

          <div class="form-group">
            <label class="control-label" attr.for="campagnaVenditaId"><span class="badge badge-secondary">Campagna di Vendita</span></label>
            <p-editor id="campagnaVenditaId" maximizable="true" formControlName="campagnaVendita"></p-editor>
          </div>

        </fieldset>
      </div>

    </form>
    <ng-container *ngIf="idProdotto > 0">
      <label class="control-label" ><span class="badge badge-secondary">DOCUMENTI DEL PRODOTTO</span></label>
      
      <p-table #dt [value]="files"
        [paginator]="true"
        csvSeparator=";"
        [rows]="15" 
        [pageLinks]="5"
        [rowsPerPageOptions]="[5,10,20,30,40,50]">
        <ng-template pTemplate="header">
          <tr>
            <th>Titolo File</th>
            <th>Nome File</th>
            <th>BodyPart Name</th>
            <th>Id Tipo File</th>
            <th>Tipo File</th>
            <th>Template</th>
            <th>Saved File</th>
            <th>###</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{record.titoloFile}}</td>
            <td>{{record.nomeFile}}</td>
            <td>{{record.bodyPartNomeFile}}</td>
            <td>{{record.idTipoFile}}</td>
            <td>{{record.descrizioneFile}}</td>
            <td>{{record.template}}</td>
            <td>{{record.daSalvare}}</td>
            <td>
              <a class="btn btn-primary" (click)="openPopup(record)" pTooltip="Update" tooltipPosition="bottom"><i class="fa fa-upload" aria-hidden="true"></i></a>
              <a class="btn btn-success" pTooltip="Download" tooltipPosition="bottom" (click)="downloadFile(record.id, record.nomeFile)"><i class="fa fa-download" aria-hidden="true"></i></a>
              <a class="btn btn-danger" (click)="eliminaFile(record)" pTooltip="Delete" tooltipPosition="bottom"><i class="fa fa-trash" aria-hidden="true"></i></a>
            </td>
          </tr>
        </ng-template>
      </p-table>
  
  
      <app-dropzone-files
        [idProdotto]="idProdotto"
        [idEntita]="prodotto?.idBanca"
        (fileUploadedSuccess)="onFileUploadedSuccess()"
        (fileUploadedError)="onFileUploadedError()"></app-dropzone-files>
    </ng-container>
  </div>

  <br />

  <div class="card-footer bg-secondary" style="text-align: center;">
    <button type="submit" form="prodottoFormId" class="btn btn-success"[disabled]="!prodottoForm.valid || prodottoExists" >Salva</button>
  </div>

  <p-dialog header="Errore server" [(visible)]="showErrorDialog" [modal]="true">{{errorMessage}}</p-dialog>

  <ng-template #modalFileProdottoEdit></ng-template>
  <p-toast closable="false" position="bottom-right"></p-toast>