import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { FileUploader } from 'ng2-file-upload';

import { PaginaInputModel, PaginaViewModel } from 'app/pagina/pagina';
import { GenericValidator } from 'app/shared-app/generic-validator';
import { FileService } from 'app/services/file.service';
import { DialogImplementation } from 'app/interfaces/DialogImplementation';
import { FileViewModel } from 'app/models/FileViewModel';
import { PaginaService } from 'app/pagina/pagina.service';
import { CommonService } from 'app/services/common.service';
import { DisplayMessageModel } from '../../models/DisplayMessageModel';
import { Message, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-pagina-edit',
  templateUrl: './pagina-edit.component.html',
  styleUrls: ['./pagina-edit.component.css']
})
export class PaginaEditComponent implements OnInit, AfterViewInit, OnDestroy, DialogImplementation {

  pageTitle = '';

  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  idPagina: number;

  paginaForm: FormGroup;
  pagina: PaginaInputModel
  paginaForSave: PaginaViewModel;

  entitas: Observable<SelectItem[]>;

  tipiFile: SelectItem[];
  templatesFile: SelectItem[];
  toSaveOptions: SelectItem[];

  showFilesUpload: boolean;
  files: any[] = [];

  private sub: Subscription;

  // Use with the generic validation message class
  displayMessage = new DisplayMessageModel();
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  paginaExists: boolean;

  msgs: Message[] = [];

  uploadedFiles: any[] = [];

  public uploader: FileUploader = new FileUploader({url: environment.webApiUrl + environment.webApiUploadFile, authTokenHeader: localStorage.getItem('access_token')});

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private fileService: FileService,
              private paginaService: PaginaService,
              private commonService: CommonService) {
      this.validationMessages = {
        titoloPagina: {
            required: 'Obbligatorio',
            minlength: 'Must be at least three characters.',
            maxlength: 'Cannot exceed 50 characters.'
        },
        contenuto: {
          required: 'Obbligatorio',
          minlength: 'Must be at least 6 characters.',
          maxlength: 'Cannot exceed 500 characters.'
        },
        tags: {
          required: 'Obbligatorio'
        },
        isOnlyPdf: {
          required: 'Obbligatorio'
        }
    };
    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  /**
   *
   */
  ngOnInit() {
    this.showLoading = true;

    this.sub = this.route.params.subscribe(params => this.onRouteChange(params));

    const validationFieldsRules = this.getValidationFieldsRules();
    this.paginaForm = this.fb.group(validationFieldsRules);
    this.tipiFile = this.commonService.getTipiFile();
    this.entitas = this.commonService.getEntita();
  }

  /**
   *
   * @param params
   */
  private onRouteChange(params) {
    this.showLoading = false;

    this.idPagina = +params['id'];

    if (this.idPagina === 0)
      this.enableFields();
    else
      this.disableFields();

    this.getPagina(this.idPagina);
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   *
   */
  ngAfterViewInit(): void {

  }

  /**
   *
   * @param id
   */
  getPagina(id: number): void {
    this.showLoading = true;
    this.paginaService.getPagina(id).subscribe(
            (pagina: PaginaInputModel) => this.onPaginaRetrieved(pagina),
            (error: any) => this.httpErrorMessage(error, 'Errore nel recupero dati pagina'));
  }

  /**
   *
   */
  public onFileUploadedSuccess() {
    this.getPagina(this.idPagina);
    this.msgs.push({severity: 'success', summary: 'File caricato', detail: ''});
  }

  /**
   *
   */
  public onFileUploadedError() {
    this.msgs.push({severity: 'error', summary: 'Errore nel caricamento del file', detail: ''});
  }

  /**
   *
   * @param pagina
   */
  onPaginaRetrieved(pagina: PaginaInputModel) {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;

    if (this.paginaForm)
        this.paginaForm.reset();

    if (_.isNil(pagina))
      pagina = new PaginaInputModel();

    this.pagina = pagina;

    this.pageTitle = pagina.id === 0 ? 'Aggiungi Pagina' : `Modifica Pagina: ${this.pagina.titoloPagina}`;

    this.files = [];

    _.forEach(this.pagina.files, (file, y) => {
      file.virtualPath = file.virtualPath ? file.virtualPath.split(',') : [];
      this.files.push(file);
    })

    this.paginaForm.patchValue({
        titoloPagina: this.pagina.titoloPagina,
        contenuto: this.pagina.contenuto,
        permalink: this.pagina.permalink,
        tags:  this.pagina.tags ? this.pagina.tags.split(',') : '',
        isOnlyPdf: this.pagina.isOnlyPdf,
        idEntita: this.pagina.idEntita
    });
  }

  /**
   *
   */
  savePagina() {
    if (this.paginaForm.dirty && this.paginaForm.valid) {

      const paginaForSave = new PaginaViewModel(this.paginaForm.value);

      this.showLoading = true;
      this.paginaService.savePagina(this.pagina.id, paginaForSave).subscribe(
          () => this.onSaveComplete(),
          (error: any) => this.httpErrorMessage(error, 'Errore nel salvataggio dei dati prodotto'));
    }
  }

  /**
   *
   */
  private onSaveComplete() {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.msgs.push({severity: 'success', summary: 'Modifica registrata', detail: ''});
  }

  /**
   *
   * @param titoloFile
   * @param file
   */
  aggiornaFile(titoloFile, tipoFile, virtualPath, file: FileViewModel) {
    this.showLoading = true;

    let  fileToSend = Object.assign({}, file);

    fileToSend.titoloFile = titoloFile.value;
    fileToSend.idTipoFile = tipoFile.value;
    fileToSend.virtualPath = virtualPath.value.join();

    this.fileService.updateFile(fileToSend).subscribe(
        (response) => this.onUpdatedFile(),
        (error: any) => this.httpErrorMessage(error, 'Errore nell\' aggiornamento del file'));
  }

  /**
   *
   */
  onUpdatedFile() {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.msgs.push({severity: 'success', summary: 'File aggiornato', detail: ''});
  }

  /**
   *
   * @param filename
   */
  eliminaFile(file: FileViewModel) {
    this.showLoading = true;
    this.fileService.deleteFile(file).subscribe(
        () => this.onFileEliminated(file),
        (error) => this.httpErrorMessage(error, 'Errore nell\' eliminazione del file'));
  }

  /**
   *
   */
  onFileEliminated(file) {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    _.remove(this.files, (n) => n.nomeFile === file.nomeFile);
    this.msgs.push({severity: 'success', summary: 'File eliminato', detail: ''});
  }

  /**
   *
   */
  public downloadFile(idFile: number, nomeFile: string) {
      this.commonService.downloadFile(idFile, nomeFile);
  }

  /**
   *
   */
  private httpErrorMessage(error: any, message: string) {
    this.showLoading = false;
    this.showErrorDialog = true;
    this.errorMessage = error._body;
    this.msgs.push({severity: 'error', summary: message, detail: ''});
  }

  /**
   *
   */
  private getValidationFieldsRules() {
    return {
      titoloPagina: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      contenuto: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(5000)]],
      permalink: '',
      tags: '',
      isOnlyPdf: false,
      idEntita: null
    }
  }

  /**
   *
   */
  private enableFields() {
    this.showFilesUpload = false;
  }

  /**
   *
   */
  private disableFields() {
    this.showFilesUpload = true;
  }
}
