export class DisplayMessageModel {
  nome: string;
  cognome: string;
  matricola: string;
  email: string;
  nomeProdotto: string;
  codiceTsunami: string;
  entitaBanca: string;
  entitaCompagnia: string;
  codiceTariffaBanca: string;
  codiceTariffaCompagnia: string;
  version: string;
  source: string;
  entita: string;
  titoloPagina: string;
  contenuto: string;
  tags: string;
  prodottoBundle: string;
  permalink: string;
  codiceRui: string;
  descrizioneEntita:string;
  codiceEntita:string;
  ragioneSociale:string;
  tipoEntita:string;
  centroConsortile:string;
  contattoReclamo:string;
}
