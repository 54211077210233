import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { HttpHelpersService } from '../services/http-helpers.service';
import { PagineInputModel } from 'app/pagina/models/pagine-input.model';
import { PaginaInputModel, PaginaViewModel } from 'app/pagina/pagina';

@Injectable()
export class PaginaService {

  constructor(private http: HttpClient, private httpHelpers: HttpHelpersService) { }

  getPagina(id: number): Observable<any> {
    const filters = { id: id }
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/pagine/getPagina', { params: httpParams });
  }

  /**
   *
   * @param filters
   */
  getPagine(filters: PagineInputModel): Observable<any> {
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/pagine/getPagine', { params: httpParams });
  }

  /**
   *
   */
  savePagina(idPagina: number, pagina: PaginaViewModel): Observable<PaginaViewModel> {
    pagina.tags = pagina.tags.toString();
    if (idPagina === null || idPagina === 0)
          return this.createPagina(pagina);
      return this.updatePagina(idPagina, pagina);
  }

  /**
   *
   * @param pagina
   */
  createPagina(pagina: PaginaViewModel): Observable<PaginaViewModel> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/pagine/createPagina`;
    return this.http.post<PaginaViewModel>(url, pagina, { headers: headers });
  }

  /**
   *
   */
  updatePagina(idPagina, pagina: PaginaViewModel): Observable<PaginaViewModel> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/pagine/updatePagina/${idPagina}`;
    return this.http.put<PaginaViewModel>(url, pagina, { headers: headers });
  }

  /**
   *
   * @param idPagina
   */
  public deletePagina(idPagina: number): Observable<PaginaViewModel> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/pagine/deletePagina/${idPagina}`;
    return this.http.delete<PaginaViewModel>(url, { headers: headers });
  }
}
