import { Component, OnInit } from '@angular/core';;


import { OperatoreService } from '../operatore.service';
import { Operatore } from './../../operatore/operatore';
import { DialogImplementation } from '../../interfaces/DialogImplementation';
import { OperatoriInputModel } from 'app/operatore/models/operatori-input.model';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SelectItem, ConfirmationService, LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-operatore-list',
  templateUrl: './operatore-list.component.html',
  styleUrls: ['./operatore-list.component.css']
})
export class OperatoreListComponent implements OnInit, DialogImplementation {
  
  showErrorDialog: boolean;
  errorMessage: string;
  showLoading = true;

  filters: OperatoriInputModel;
  filterEntityType: any;

  totaleOperatori: number;
  operatori: Operatore[];

  tipiEntita: SelectItem[] = [{label: 'Tutte le entità', value: ''}, {label: 'Banca', value: 'B'}, {label: 'Compagnia', value: 'C'}, {label: 'GEFASS', value: 'G'}];

  maxRows: number = 15;

  constructor(
    private operatoreService: OperatoreService,
    private confirmationService: ConfirmationService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.filterEntityType =+ this.route.snapshot.queryParams['entitytype'] || undefined
  }

  /**
   *
   * @param event
   */
  loadOperatori(event: LazyLoadEvent) {
    this.showLoading = true;
    const rows = event.rows || this.maxRows;
    const page = Math.ceil(event.first / rows);

    const entityType = (event.filters.tipoEntita && event.filters.tipoEntita.value && event.filters.tipoEntita.value !== 0)
      ? event.filters.tipoEntita.value
      : this.filterEntityType;

    const { nome, cognome, matricola } = event.filters;
    this.filters = {
      ...new OperatoriInputModel(),
      filter: event.globalFilter || '',
      entityType: entityType || '',
      nome: nome ? nome.value : '',
      cognome: cognome ? cognome.value : '',
      matricola: matricola ? matricola.value : '',
      page: page,
      perPage: rows
    };
    
    this.operatoreService.getOperatori(this.filters)
      .subscribe(
        response => this.onOperatoriRetrievedSuccess(response),
        error => this.onOperatoriRetrievedError(error)
      );
  }

  /**
   *
   * @param response
   */
  onOperatoriRetrievedSuccess(response) {
    this.showLoading = false;
    this.showErrorDialog = false;
    this.errorMessage = undefined;
    this.totaleOperatori = response.totaleRecords
    this.operatori = response.records;
  }

  /**
   *
   * @param error
   */
  onOperatoriRetrievedError(error) {
    this.showErrorDialog = true;
    this.errorMessage = error._body;
  }

  /**
   *
   * @param operatoreId
   */
  eliminaOperatore(operatoreId) {
    this.confirmationService.confirm({
        message: 'Confermi la cancellazione dell\'operatore ?',
        accept: () => {
          this.showLoading = true;
          this.operatoreService.deleteOperatore(operatoreId)
          .pipe(finalize(() => this.showLoading = false))
          .subscribe(() => {
            this.showErrorDialog = false;
            this.errorMessage = undefined;
          }, err => {
            this.showErrorDialog = true;
            this.errorMessage = err._body;
          });
        }
    });
  }
}
