import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router  } from '@angular/router';
import { Observable, Subscription  } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OperatoreService } from '../operatore.service';
import { CommonService } from './../../services/common.service';
import { GenericValidator } from '../../shared-app/generic-validator';
import { Operatore } from '../operatore';
import { OperatoreConstants } from '../operatore.constants';
import { KeyValue } from '../../models/key-value';
import { DialogImplementation } from '../../interfaces/DialogImplementation';
import { DisplayMessageModel } from 'app/models/DisplayMessageModel';
import { OperatoriInputModel } from 'app/operatore/models/operatori-input.model';
import { AbilitazioneViewModel } from 'app/models/AbilitazioneViewModel'; 
import { AbilitazioneModel } from 'app/models/AbilitazioneModel';
import { ProdottoViewModel } from 'app/models/ProdottoViewModel';
import { OperatorClaims } from 'app/models/OperatorClaims';
import { NgForm } from '@angular/forms';
import { AspNetRolesModel } from 'app/models/AspNetRolesModel';
import { Message } from 'primeng/api';


@Component({
  selector: 'app-operatore-edit',
  templateUrl: './operatore-edit.component.html',
  styleUrls: ['./operatore-edit.component.css']
})
export class OperatoreEditComponent implements OnInit, AfterViewInit, OnDestroy, DialogImplementation {

    pageTitle = '';

    showErrorDialog: boolean;
    showOnlyRegistry: boolean;
    errorMessage: string;
    showLoading = true;

    operatoreForm: FormGroup;
    operatore: Operatore;

    banche: Observable<KeyValue[]>;
    compagnie: Observable<KeyValue[]>;
    enitaAon: KeyValue[] = [
      {label: 'AON', value: '118'}
    ];
    ruoli: Observable<AspNetRolesModel[]>; 
    claimsname: Observable<KeyValue[]>;

    campoVisibile = false;

    private sub: Subscription;

    filters: OperatoriInputModel;
    totaleAbilitazioni: number;
    AbilitazioniList: AbilitazioneViewModel[];
    abilitaModel: AbilitazioneModel;
    ProdottoList: ProdottoViewModel[];
    abilitaParams: AbilitazioneModel;

    OpClaimsList: OperatorClaims[];
    OpclaimsCount: number;

    // Use with the generic validation message class
    displayMessage = new DisplayMessageModel();
    private validationMessages: { [key: string]: { [key: string]: string } };
    private genericValidator: GenericValidator;

    userExists = false;
    msgs: Message[] = [];

    uploadedFiles: any[] = [];

    currentPage: number;

    Idpo: string;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private operatoreService: OperatoreService,
                private commonService: CommonService,
                private constantsOperatore: OperatoreConstants) {

        this.validationMessages = this.getValidationMessages();
        this.genericValidator = new GenericValidator(this.validationMessages);
  }

  /**
   *
   */
  ngOnInit() {
    const validationFieldsRules = this.getValidationFieldsRules();
    this.operatoreForm = this.fb.group(validationFieldsRules);

    this.sub = this.route.params.subscribe(params => this.getOperatore(params['id']));

    this.banche = this.commonService.getBanche();
    this.compagnie = this.commonService.getCompagnie();
    this.ruoli = this.commonService.getRuoli();
    this.claimsname = this.commonService.getClaimsNames();
  }

  onSubmit(it: NgForm) {
    console.log(it.value);  // { first: '', last: '' }
    console.log(it.valid);  // false
  }

  /**
   *
   */
  private getValidationFieldsRules() {
    return {
      nome:       ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      cognome:    ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      matricola : ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      email :     ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.email]],
      tipoEntita: [''],
      idEntita:   ['', [Validators.required]],
      ruolo:      ['', [Validators.required]],
      codiceRui:  [''],
      abilitatoIsvap: [''],
      abilitatoMifid: [''],
      eliminato: [''],
      prodottovalue: [''],
      valoredelclaim: ['']
    }
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.operatoreForm.valueChanges.pipe()
      .subscribe((operatore: Operatore) => {
        let operatoreParamsInserted = operatore.nome !== '' && operatore.cognome !== '' && operatore.matricola !== '';
        if (operatoreParamsInserted && this.operatore.id == null) {
          this.operatoreService
            .checkOperatoreExists(operatore.matricola, operatore.email)
            .subscribe((exists: boolean) => {
              this.userExists = exists;
            });
        }

        this.displayMessage = this.genericValidator.processMessages(this.operatoreForm);

        if (this.operatore.id !== null) {
          this.operatoreForm.controls['nome'].disable();
          this.operatoreForm.controls['cognome'].disable();
          this.operatoreForm.controls['matricola'].disable();
          this.operatoreForm.controls['idEntita'].disable();
        }
      });
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   *
   * @param operatore
   */
  onOperatoreRetrieved(operatore: Operatore): void {
      if (this.operatoreForm)
          this.operatoreForm.reset();

      if (operatore == null)
        operatore = new Operatore();

      this.operatore = operatore;
      
      this.campoVisibile = operatore.tipoEntita === this.constantsOperatore.tipoEntita.compagnia || this.operatore.id == null;

      if (this.campoVisibile)
         this.operatoreForm.controls.idEntita.setValidators([Validators.required]);
      else {
        this.operatoreForm.controls.idEntita.clearValidators();
        this.operatoreForm.controls.nome.clearValidators();
        this.operatoreForm.controls.cognome.clearValidators();
        this.operatoreForm.controls.matricola.clearValidators();
        this.operatoreForm.controls.email.clearValidators();
        this.operatoreForm.controls.tipoEntita.clearValidators();
        this.operatoreForm.controls.codiceRui.clearValidators();
        this.operatoreForm.controls.abilitatoIsvap.clearValidators();
        this.operatoreForm.controls.abilitatoMifid.clearValidators();
        this.operatoreForm.controls.eliminato.clearValidators(); 
      }

      if (this.operatore.id == null) {
        this.pageTitle = 'Aggiungi Operatore';
        this.showOnlyRegistry = true;
      } else {
        this.pageTitle = `Modifica Operatore: ${this.operatore.nome} ${this.operatore.cognome}`;
        this.getAbilitazione();
        this.getGetClaimType();
        this.getProdotto();
      }

      // Update the data on the form
      this.operatoreForm.patchValue({
          nome: this.operatore.nome,
          cognome: this.operatore.cognome,
          matricola: this.operatore.matricola,
          username: this.operatore.username,
          email: this.operatore.email,
          abilitatoIsvap: this.operatore.abilitatoIsvap,
          abilitatoMifid: this.operatore.abilitatoMifid,
          eliminato: this.operatore.eliminato,
          dataAbilitazione: this.operatore.dataAbilitazione,
          tipoEntita: this.operatore.tipoEntita,
          idEntita: this.operatore.idEntita,
          ruolo: this.operatore.ruolo,
          codiceRui: this.operatore.codiceRui,
      });
  }


  /**
   *
   */
  saveOperatore() {
    if (this.userExists) {
      this.msgs.push({severity: 'error', summary: 'Operatore duplicato', detail: 'Matricola/Email già presente'});
    } else if (this.operatoreForm.dirty && this.operatoreForm.valid) {
      // Copy the form values over the operatore object values
      let o: Operatore = Object.assign({}, this.operatore, this.operatoreForm.value);

      if (o.idEntita == null)
        o.idEntita = 118;

      this.showLoading = true;        
      this.operatoreService.saveOperatore(o)
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(() => {
          this.showErrorDialog = false;
          this.errorMessage = undefined;
          this.onSaveComplete(true, 'success', 'Salvataggio avvenuto con successo')
        }, (error: any) => {
          this.showErrorDialog = true;
          this.errorMessage = error._body;
        }
      );
    } else if (!this.operatoreForm.dirty) {
        this.onSaveComplete(false, 'info', 'Nessuna modifica registrata');
    }
  }

  /**
   *
   */
  onSaveComplete(reset: boolean, severity: string, summary: string): void {
    if (reset) this.operatoreForm.reset();
    this.msgs.push({severity: severity, summary: summary, detail: ''});
  }

  /**
   *
   */
  handleChangeTipoEntita() {
    if (this.operatoreForm.controls.tipoEntita.value === this.constantsOperatore.tipoEntita.gef) {
      // Gefass
      this.operatoreForm.controls.idEntita.reset();
      this.operatoreForm.controls.ruolo.reset();
      this.operatoreForm.controls.idEntita.disable();
      this.operatoreForm.controls.ruolo.disable();
    } else {
      // Banca / Compagnia
      this.operatoreForm.controls.idEntita.enable();
      this.operatoreForm.controls.ruolo.enable();
    }
  }

  /**
   *
   */
  private getValidationMessages() {
    return {
        nome: {
          required: 'Nome è obbligatorio',
          minlength: 'Nome must be at least three characters.',
          maxlength: 'Nome cannot exceed 50 characters.'
        },
        cognome: {
          required: 'Cognome is required.',
          minlength: 'Nome must be at least three characters.',
          maxlength: 'Nome cannot exceed 50 characters.'
        },
        email: {
          required: 'Email is required.',
          minlength: 'Email must be at least three characters.',
          maxlength: 'Email cannot exceed 50 characters.',
          email: 'Deve essere un formato email valido'
        },
        matricola: {
          required: 'Matricola is required.'
        }
    };
  }

  /**
   *
   * @param id
   */
   getOperatore(idOperatore: string): void {
    this.showLoading = true;
    this.operatoreService.getOperatore(idOperatore)
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(
        (operatore: Operatore) => {
          this.showErrorDialog = false;
          this.errorMessage = undefined;
          this.onOperatoreRetrieved(operatore)
        },
        (error: any) => {
          this.showErrorDialog = true;
          this.errorMessage = error._body;
        });
  }

  /**
   *
   */
  getGetClaimType() {
    this.showLoading = true;
    this.operatoreService.getGetClaimType(this.operatore.id)
    .subscribe
      (response => {
        this.showErrorDialog = false;
        this.OpClaimsList = response.records; 
        this.OpclaimsCount = response.totaleRecords; 
      },
      error => {
        this.showErrorDialog = true;
        this.errorMessage = error._body;
      });
  }

  onInsertClaims(claimsname: string, valoredelclaim: string) {
    this.showLoading = true;

    let claimsnames = claimsname.trim();
    let idOperatore = this.operatore.id;
    
    this.operatoreService.saveClaimsOperarore(claimsnames, valoredelclaim, idOperatore)
    .pipe(finalize(() => this.showLoading = false))
    .subscribe(() => {
        this.getOperatore(this.operatore.id);
        this.showErrorDialog = false;
        this.msgs.push({severity: 'success', summary: 'i dati sono salvati.', detail: ''});
      },
      (error) => {
        this.showErrorDialog = true;
        this.errorMessage = error._body;
      }
    );
  }

  onCancellaClaims(idOperatore: string, claim_type: string) {
    this.showLoading = true;
    this.operatoreService.deleteClaimsOperatore(idOperatore, claim_type)
    .pipe(finalize(() => this.showLoading = false))
      .subscribe(ctx => {
          this.getOperatore(this.operatore.id);
          this.showErrorDialog = false;
          this.showLoading = false;
          this.msgs.push({severity: 'success', summary: 'Modifica registrata', detail: ''});
        },
        (error: any) => {
          this.showErrorDialog = true;
          this.errorMessage = error._body;
          this.showLoading = false;
        });
  }

  /**
   *
   */
  getProdotto() {
    this.showLoading = true;
    this.operatoreService.getProdottoOperatore(this.operatore.idEntita)
    .subscribe(response => { 
      this.ProdottoList = response;
      this.showLoading = false;
    }, err => {
      this.showErrorDialog = true;
      this.errorMessage = err._body;
    });
  }

  /**
   *
   */
  updateAbilitation(id: string): void {
    this.showLoading = true;
    this.operatoreService.updateAbilitation(id)
    .pipe(finalize(() => this.showLoading = false))
      .subscribe(
        (operatore: Operatore) => {
          this.getOperatore(this.operatore.id);
          this.showErrorDialog = false;
          this.msgs.push({severity: 'success', summary: 'Modifica registrata', detail: ''});
        },
        (error: any) => {
          this.showErrorDialog = true;
          this.errorMessage = error._body;
        });
  }

  // Questo metodo per popolare la griglia abilitazione
  // nella maschera Operatore Edit
  getAbilitazione() {
    this.showLoading = true;
    this.operatoreService.getAbilitazioneOperatore(this.operatore.id, this.operatore.idEntita)
    .pipe(finalize(() => this.showLoading = false))
    .subscribe(response => {
      this.totaleAbilitazioni = response.totaleRecords
      this.AbilitazioniList = response.records;
      this.showLoading = false;
    }, err => {
      this.showErrorDialog = true;
      this.errorMessage = err._body;
    });
  }

  // Modificare il valore del campo abilitato dal true al false
  // secondo l'id della riga selezionato nella datatable.
  cancellaAbilitazione(id: string): void {
    if (id) {
      this.updateAbilitation(id)
    this.errorMessage = 'Non è ancora impletato';
    }
  }

  /**
   *
   */
  addAbilitazione(codiceTariffa: string): void {
    this.showLoading = true;
    let idOperatore = this.operatore.id;
    this.operatoreService.AbilitazioneOpSave(codiceTariffa, idOperatore)
    .pipe(finalize(() => this.showLoading = false))
    .subscribe(() => {
        this.showErrorDialog = false;
        this.msgs.push({severity: 'success', summary: 'i dati sono salvati.', detail: ''});
      }, error => {
        this.showErrorDialog = true;
        this.errorMessage = error._body;
      }
    );
  }

}