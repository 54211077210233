import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ProdottoEditComponent } from './prodotto-edit/prodotto-edit.component';
import { ProdottoListComponent } from './prodotto-list/prodotto-list.component';

import { SharedAppModule } from '../shared-app/shared-app.module';

import { ProdottoService } from './prodotto.service';

import { ModalProdottoEditComponent } from './modal-prodotto-edit/modal-prodotto-edit';

import { SelectButtonModule, ButtonModule, TooltipModule, MultiSelectModule } from 'primeng';

const prodottoRoutes = [
  { path: '', component: ProdottoListComponent },
  { path: 'prodotto/new', component: ProdottoEditComponent },
  { path: 'prodotto/:id', component: ProdottoEditComponent }
]

@NgModule({
  imports: [
    SharedAppModule,
    RouterModule.forChild(prodottoRoutes),
    SelectButtonModule,
    ButtonModule,
    TooltipModule,
    FormsModule,
    MultiSelectModule,
  ],
  declarations: [
    ProdottoEditComponent,
    ProdottoListComponent,
    ModalProdottoEditComponent
  ],
  entryComponents: [
    ModalProdottoEditComponent
  ],
  providers: [
    ProdottoService
  ]
})
export class ProdottoModule { }
