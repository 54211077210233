<ngx-loading [show]="showLoading"></ngx-loading>

<p-dialog (onHide)="chiudiDialogModal()" [(visible)]="displayModal"  [baseZIndex]="10000" [closable]="true" [showHeader]="true" [contentStyle]="{'max-height':'400px', 'min-height':'300px','margin-top':'50px'}" position="top" >
  <p-header>Maschera aggiornamento file</p-header>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="">
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="control-label" for="nomeId"><span class="badge badge-secondary">Titolo File</span></label>
                <input class="form-control" #titoloFile type="text" [value]="file.titoloFile" placeholder="Titolo File" />
              </div>
              <div class="form-group col-md-6">
                <label class="control-label" for="nomeIds"><span class="badge badge-secondary">Tipo File</span></label>
                
                <select class="form-control" #tipoFile>
                  <option *ngFor="let tipoFile of tipiFile" [value]="tipoFile.value" [selected]="tipoFile.value === file.idTipoFile">
                    {{tipoFile.label}}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="control-label" for="nomeId"><span class="badge badge-secondary">Template File</span></label>
                <select class="form-control" #templateFile>
                  <option *ngFor="let templateFile of templatesFile" [value]="templateFile.value" [selected]="templateFile.value === file.template">
                    {{templateFile.label}}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6" [hidden]="templateFile == 'Nessun template'">
                <label class="control-label" for="nomeIds" [hidden]="templateFile.value == 'null'"><span class="badge badge-secondary">Azione</span></label>
                <div class="" [hidden]="templateFile == null">
                  <select class="form-control" #daSalvare [hidden]="templateFile.value == 'null'">
                    <option *ngFor="let daSalvare of toSaveOptions" [value]="daSalvare.value" [selected]="daSalvare.value === file.daSalvare">
                      {{daSalvare.label}}
                    </option>
                  </select>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    

  <p-footer [style]="{'z-indez':1004}">
    <button type="button" class="btn btn-success" (click)="chiudiDialogModal()">Chiudi</button>
    <button type="button" class="btn btn-primary" (click)="aggiornaFile(titoloFile, tipoFile, templateFile, daSalvare, file)">Aggiorna</button>
  </p-footer>
</p-dialog>