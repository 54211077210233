import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHelpersService {

  constructor() { }

  /**
   *
   * @param params
   */
  public setHttpParams(params) {
    let httpParams = new HttpParams();
    _.forIn(params, (value, key) => httpParams = httpParams.append(key, value));
    return httpParams;
  }

/**
   *
   * @param params
   */
  public setHttpHeaders() {
    let httpHeaders = new HttpHeaders({ 'Content-Type':  'application/json' });
    return httpHeaders;
  }

  /**
   *
   * @param error
   */
  public handleHttpError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error);
    return Observable.throw(error || 'Server error');
  }
}
