import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

import { CommonService } from './../services/common.service';
import { FileViewModel } from './../models/FileViewModel';
import { AuthService } from './../services/auth/auth.service';
import { HttpHelpersService } from 'app/services/http-helpers.service';
import { map } from 'lodash';



@Injectable()
export class FileService {

  constructor(private commonService: CommonService, public http: HttpClient, public authService: AuthService, private httpHelpers: HttpHelpersService ) { }

  /**
   *
   * @param nomeFile
   */
  public deleteFile(file: FileViewModel): Observable<any> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/files/deleteFile/${file.id}/${file.idEntita}/${file.idProdotto}`;
    return this.http.delete(url, { headers: headers });
  }

  /**
   *
   * @param file
   */
  public updateFile(file: FileViewModel) {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/files/updateFile/${file.id}`;

    let fileViewModel = Object.assign(new FileViewModel(), file);

    return this.http.put(url, fileViewModel, { headers: headers });
  }

  
}
