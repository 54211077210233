import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FileUploadModule } from 'ng2-file-upload';

import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
// import { UploadFilesComponent } from './upload-files/upload-files.component';
import { DropzoneFilesComponent } from './dropzone-files/dropzone-files.component';
import { DropzoneFilesDirective } from './dropzone-files.directive';
import { ProgressComponent } from './progess/progess.component';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { TableModule, PanelModule, InputNumberModule, InputTextareaModule, EditorModule, RatingModule, ToggleButtonModule, DropdownModule, FieldsetModule, DataViewModule, RadioButtonModule, DialogModule, ChipsModule, ConfirmDialogModule, ToastModule, CalendarModule } from 'primeng';

@NgModule({
  declarations: [
    // UploadFilesComponent,
    DropzoneFilesComponent,
    DropzoneFilesDirective,
    ProgressComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.rectangleBounce,
      backdropBackgroundColour: 'rgba(146,202,244,0.7)',
      primaryColour: '#1BA1E2',
      secondaryColour: '#ffffff',
      tertiaryColour: '#1BA1E2'
  }),
  FormsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    PanelModule,
    InputNumberModule,
    InputTextareaModule,
    EditorModule,
    RatingModule,
    ToggleButtonModule,
    DropdownModule,
    FieldsetModule,
    DataViewModule,
    RadioButtonModule,
    DialogModule,
    ChipsModule,
    ConfirmDialogModule,
    CalendarModule,
    // UploadFilesComponent,
    DropzoneFilesComponent,
    LoadingModule,
    SharedModule,
    ToastModule
  ],
  providers: [
    ConfirmationService
  ]
})
export class SharedAppModule { }

