import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { Operatore } from '../operatore/operatore';

import { HttpHelpersService } from 'app/services/http-helpers.service';
import { OperatoriInputModel } from './models/operatori-input.model';

@Injectable()
export class OperatoreService {

  constructor(private http: HttpClient, private httpHelpers: HttpHelpersService) { }

  /**
   *
   * @param page
   */
  getOperatori(filters: OperatoriInputModel): Observable<any> {
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/operatori/getOperatori', { params: httpParams });
  }

  /**
   *
   * @param id
   */
  getOperatore(id: string): Observable<any> {
    const filters = { id: id }
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/operatori/getOperatore', { params: httpParams });
  }

  /**
   * Controllo a runtime che la combinanzione N-C-M non sia già presente
   * @param nome
   * @param cognome
   * @param matricola
   */
  checkOperatoreExists(matricola: string, email: string) {
      const filters = {
        matricola: matricola,
        email: email
      };
      let httpParams = this.httpHelpers.setHttpParams(filters);
      return this.http.get(environment.webApiUrl + 'api/operatori/checkOperatoreExists', { params: httpParams });
  }

  /**
   *
   * @param operatore
   */
  saveOperatore(operatore: Operatore): Observable<Operatore>  {
    if (operatore.id == null || operatore.id === '0')
        return this.createOperatore(operatore);
    return this.updateOperatore(operatore);
  }

  /**
   *
   * @param operatore
   * @param options
   */
  private createOperatore(operatore: Operatore): Observable<Operatore> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/createOperatore`;
    return this.http.post<Operatore>(url, operatore, { headers: headers });
  }

  /**
   *
   * @param operatore
   * @param options
   */
  private updateOperatore(operatore: Operatore): Observable<Operatore> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/updateOperatore/${operatore.id}`;
    return this.http.put<Operatore>(url, operatore, { headers: headers });
  }

  /**
   *
   * @param operatore
   * @param options
   */
  public deleteOperatore(idOperatore: number): Observable<Operatore> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/deleteOperatore/${idOperatore}`;
    return this.http.delete<Operatore>(url, {headers: headers});
  }

  /**
   *
   * @param id
   */
  //  updateAbilitation(id: string): Observable<any> {
  //   const filters = { id: id }
  //   let httpParams = this.httpHelpers.setHttpParams(filters);
  //   return this.http.put(environment.webApiUrl + 'api/operatori/updateAbilitazione', { params: httpParams });
  // }
  public updateAbilitation(idOperatore: string):  Observable<Operatore> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/updateAbilitazione/${idOperatore}`;
    return this.http.put<Operatore>(url, {headers: headers});
  }

  /**
   *
   * @param page
   */
   getGetClaimType(idOperatore: string): Observable<any> {
    const filters = { idOperatore: idOperatore }
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/operatori/getTypeClaims', { params: httpParams });
  }

  getAbilitazioneOperatore(idOperatore: string, idEntita: number): Observable<any> {
    const filters = { idOperatore: idOperatore, idEntita: idEntita }
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/operatori/getAbilitazioneOp', { params: httpParams });
  }

  getProdottoOperatore(idEntita: number): Observable<any> {
    const filters = { idintita: idEntita}
    let httpParams = this.httpHelpers.setHttpParams(filters);
    return this.http.get(environment.webApiUrl + 'api/operatori/getProdotto', { params: httpParams });
  }

  AbilitazioneOpSave(codiceTariffa: string , idOperatore: string): Observable<any> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/saveaddAbilitazione/${codiceTariffa}/${idOperatore}`;
    return this.http.put(url, {headers: headers});
  }

  saveClaimsOperarore(claimsnames: string, valoredelclaim: string, idOperatore: string): Observable<any> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/saveClaimsOperator/${claimsnames}/${valoredelclaim}/${idOperatore}`;
    return this.http.put(url, {headers: headers});
  }

  deleteClaimsOperatore(idOperatore: string, claim_type: string): Observable<any> {
    let headers = this.httpHelpers.setHttpHeaders();
    const url = `${environment.webApiUrl}api/operatori/deleteClaimsOperator/${idOperatore}/${claim_type}`;
    return this.http.put(url, { headers: headers });
  }

}
