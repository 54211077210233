<div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar" [ngClass]="toggleSidebarActive ? 'active' : ''">
        <div class="sidebar-header">
            <h3>Vetrina Manager</h3>
        </div>
        
        <ul class="list-unstyled components">
            <li class="active">
                <a href="#">Homepage</a>
            </li>
            <ng-container *ngIf="authService.hasValidToken">
                <li>
                    <a href="#vetrineSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Vetrine</a>
                    <ul class="collapse list-unstyled" id="vetrineSubmenu">
                        <li>
                            <a [routerLink]="['vetrine/new']"><i class="fa fa-pencil-square-o fa-1x" aria-hidden="true"></i> Nuova Vetrina</a> 
                        </li>
                        <li>
                            <a [routerLink]="['vetrine']"><i class="fa fa-list fa-1x" aria-hidden="true"></i> Lista Vetrine</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#" [routerLink]="['vetrine/attivita-comunicazioni']"><i class="fa fa-header fa-1x" aria-hidden="true"></i> Attivita & Comunicazioni</a>
                </li>
                <li>
                    <a href="#operatoriSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Operatori</a>
                    <ul class="collapse list-unstyled" id="operatoriSubmenu">
                        <li>
                            <a [routerLink]="['operatori/operatore']"><i class="fa fa-pencil-square-o fa-1x" aria-hidden="true"></i> Nuovo Operatore</a>
                        </li>
                        <li>
                            <a [routerLink]="['operatori']"><i class="fa fa-list fa-1x" aria-hidden="true"></i> Lista Operatori</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#prodottiSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Prodotti</a>
                    <ul class="collapse list-unstyled" id="prodottiSubmenu">
                        <li>
                            <a [routerLink]="['prodotti/prodotto/new']"><i class="fa fa-pencil-square-o fa-1x" aria-hidden="true"></i> Nuovo Prodotto</a>
                        </li>
                        <li>
                            <a [routerLink]="['prodotti']"><i class="fa fa-list fa-1x" aria-hidden="true"></i> Lista Prodotti</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#pagineSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Pagine</a>
                    <ul class="collapse list-unstyled" id="pagineSubmenu">
                        <li>
                            <a [routerLink]="['pagine/pagina']"><i class="fa fa-pencil-square-o fa-1x" aria-hidden="true"></i> Nuovo Pagina</a>
                        </li>
                        <li>
                            <a [routerLink]="['pagine']"><i class="fa fa-list fa-1x" aria-hidden="true"></i> Lista Pagine</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#entitaSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Entità</a>
                    <ul class="collapse list-unstyled" id="entitaSubmenu">
                        <li>
                            <a [routerLink]="['entita/entita']"><i class="fa fa-pencil-square-o fa-1x" aria-hidden="true"></i> Nuova Entità</a>
                        </li>
                        <li>
                            <a [routerLink]="['entita']"><i class="fa fa-list fa-1x" aria-hidden="true"></i> Lista Entità</a>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </nav>
    
    <!-- Page Content  -->
    <div id="content">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                
                <button type="button" id="sidebarCollapse" class="btn btn-default" (click)="toggleSidebar()">
                    <i class="fa fa-align-left"></i>
                </button>
                <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-align-justify"></i>
                </button>
                
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="nav navbar-nav navbar-right ml-auto">
                        <li class="nav-item active">
                            <button class="btn btn-danger btn-margin" *ngIf="!authService.hasValidToken" (click)="login()">Log In</button>
                            <button class="btn btn-success btn-margin" *ngIf="authService.hasValidToken" (click)="logout()">Log Out</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <router-outlet></router-outlet>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!--Footer-->
<footer class="footer">
    Tsunami Vetrina Manager
    <p class="copyright pull-right">&copy; {{year}} <a href="https://www.aon.com/italy/la-nostra-offerta/affinity/bancassurance/a-chi-ci-rivolgiamo.jsp">Aon Italia</a> - Portal for management document.</p>
</footer>
